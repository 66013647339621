import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function Navigation() {

  const [activePage,setActivePage]=useState('Dashboard')
  const [jwt,setJwt]=useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const location = useLocation();

  useEffect(()=>{
    setIsMobile(()=>window.innerWidth <= 768);
    if(JSON.parse(localStorage.getItem("homeshiksha-jwt")))setJwt(true)
    switch(location.pathname.split('/')[1])
    {
      case 'dashboard':setActivePage('Dashboard');break;
      case 'attendance':setActivePage('Attendance');break;
      case 'assignment':setActivePage('Assignment');break;
      case 'tutors':setActivePage('Tutors');break;
      case 'payments':setActivePage('Payments');break;
      case 'personal-info':setActivePage('MyProfile');break;
      case 'address':setActivePage('Address');break;
      case 'counselling':setActivePage('counselling');break;
      case 'settings':setActivePage('Settings');break;
      default: 
    }

  },[])

  return (
    (jwt)
    ?
      <nav className='navigation-wrapper'>
        <div><NavLink to='/dashboard' onClick={()=>{setActivePage(()=>'Dashboard')}} className={`${(activePage==='Dashboard')?'active-link':'unactive-link'}`}>Dashboard</NavLink></div>
        <div><NavLink to='/tutors' onClick={()=>{setActivePage(()=>'Tutors')}} className={`${(activePage==='Tutors')?'active-link':'unactive-link'}`}>Tutors</NavLink></div>
        {(isMobile)&&<div><NavLink to='/personal-info' onClick={()=>{setActivePage(()=>'MyProfile')}} className={`${(activePage==='MyProfile')?'active-link':'unactive-link'}`}>My Profile</NavLink></div>}
        {(isMobile)&&<div><NavLink to='/address' onClick={()=>{setActivePage(()=>'Address')}} className={`${(activePage==='Address')?'active-link':'unactive-link'}`}>Address</NavLink></div>}
        {(isMobile)&&<div><NavLink to='/counselling' onClick={()=>{setActivePage(()=>'counselling')}} className={`${(activePage==='counselling')?'active-link':'unactive-link'}`}>Counselling</NavLink></div>}
      </nav>
    :
      <nav className='navigation-wrapper'></nav>
  );
}
