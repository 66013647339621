import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import React, { useState, useCallback } from "react";

function AddressMap({ coordinates, mapSize }) {
  const containerStyle = {
    width: mapSize,
    height: "50vh",
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places", "maps"],
  });
  const [map, setMap] = useState(null);
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(coordinates);
      const padding = 0.001; // Adjust padding value for desired zoom out
      bounds.extend(
        new window.google.maps.LatLng(
          coordinates.lat + padding,
          coordinates.lng + padding
        )
      );
      bounds.extend(
        new window.google.maps.LatLng(
          coordinates.lat - padding,
          coordinates.lng - padding
        )
      );
      map.fitBounds(bounds);
      setMap(map);
    },
    [coordinates]
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      //   onClick={handleMapClick}
      options={{
        disableDefaultUI: true,
      }}
    >
      <MarkerF
        position={coordinates}
        key={`${coordinates.lat},${coordinates.lng}`}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(AddressMap);
