import React, { useEffect, useState } from 'react'
import './demo-card.css'

export default function DemoCard(props) {
    const [data,setData]=useState({})
    const [isMobile, setIsMobile] = useState(false)
    const [datetime,setDateTime]=useState()

    useEffect(()=>{
        setData(props.data)
        setIsMobile(window.innerWidth <= 768);
    },[])
  return (
    data?<div className='demo-card--container'>
        <div className='demo-card--container-top-bar-wrapper'>
            <div className='demo-card--container-top-bar-wrapper--top-left'>
                <div className='tutor-profilePic--wrapper'>
                    {(!isMobile)&&<img
                        src={`${(data.profile_pic)?data.profile_pic:"./"+data.gender+"-icon.svg"}`}
                        alt={"./"+data.gender+"-icon.svg"}
                        height={`${(data.profile_pic)?"140":"100"}`}
                        />}
                    {(isMobile)&&
                        <div>
                            <img
                                src={`${(data.profile_pic)?data.profile_pic:"./"+data.gender+"-icon.svg"}`}
                                alt={"./"+data.gender+"-icon.svg"}
                                height={`${(data.profile_pic)?"90":"50"}`}
                                />
                        </div>
                    }
                </div>
                
                {(isMobile)&&
                <div>
                    <div className='demo-card--container--info-wrapper--left--tutor-name'>{data.first_name}</div>
                </div>
                }
                </div>
                {(isMobile)&&<div className='demo-card--container-top-bar-wrapper--top-right'>
                {(!datetime)?'Yet to Schedule':'Waiting'}<span className='card-status-dot'></span>
                </div>}
            </div>

        <div className='demo-card--container--info-wrapper'>
        <div className='demo-card--container--info-wrapper--left'>
            {(!isMobile)&&
                <div>

                    <div className='demo-card--container--info-wrapper--left--tutor-name'>{data.first_name}</div>
                </div>
            }
            <div><span style={{color:"rgba(130, 130, 130, 1)",fontSize:"14px",fontWeight:"600"}}>Gender :</span> {data.gender}</div>
            <div><span style={{color:"rgba(130, 130, 130, 1)",fontSize:"14px",fontWeight:"600"}}>Age :</span> {data.age}</div>
            <div><span style={{color:"rgba(130, 130, 130, 1)",fontSize:"14px",fontWeight:"600"}}>Phone :</span> {data.phone}</div>
            <div><span style={{color:"rgba(130, 130, 130, 1)",fontSize:"14px",fontWeight:"600"}}>Experience :</span> {data.experience}</div>
            {/* <div><span style={{color:"rgba(130, 130, 130, 1)",fontSize:"14px",fontWeight:"600"}}>Expertise :</span> {data.course_names}</div> */}
        </div>
            <div className='demo-card--container--info-wrapper--right' >
                <div className='demo-card--container--info--view-wrapper'>View</div>
                <div className='demo-card--container--info--ratings--wrapper' style={{display: "flex",flexDirection:"column",alignItems:"end",justifyContent:"space-between",height:"80%"}}>
                    {/* <div className='demo-card--container--info--rating'> Ratings {data.rating}</div> */}
                    <div className='demo-card--container--info--rating'  style={{display: "flex",justifyContent:"end"}}> 

                        <label htmlFor={`datetime${data.id}`}>{datetime?datetime:'Select Time'}</label>
                        {(!datetime)&&<input
                            type='datetime-local'
                            id={`datetime${data.id}`}
                            onChange={(e) => {
                            setDateTime(e.target.value.split('T').join(' / '));
                            }}
                            style={{ width: "8%", border: "0 solid white", backgroundColor: "white" }}
                        />}

                        {/* <span>{datetime}</span>
                        <input type='datetime-local' onChange={(e)=>{setDateTime(e.target.value.split('T').join(' / '))}} style={{width:"10%",border:"0 solid white",backgroundColor:"white"}} /> */}
                    </div>
                    <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center",width:"100%"}}>
                        <div>
                            <img onClick={(e)=>{alert(` Calling ${data.first_name+" "+data.last_name}`)}}
                                    style={{cursor:"pointer"}}
                                    src={`/call-icon.svg`}
                                    alt="Tutor profile pic"
                                    height="20"
                                    />
                        </div>
                        <div className='demo-card--container--info--invite-for-demo--wrapper'>
                            Invite for Demo
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>:<></>
  )
}
