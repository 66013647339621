import ReactGA from "react-ga4";
import { EVENTS } from "./constants/ga-constants";

export const logPageView = (title = "") => {
  try {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title,
    });
  } catch (error) {}
};

export const logEvent = (eventName) => {
  try {
    ReactGA.event(EVENTS[eventName]);
  } catch (error) {
    console.log(error);
  }
};
