import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { logEvent } from '../../analytics';
import { EVENTS } from '../../constants/ga-constants';


export default function AcademyRequest() {

  const [showPopUP,setShowPopUp]=useState(false)
  const navigate = useNavigate();

  const goTo=(newFormType)=>{
    localStorage.setItem("dealData", JSON.stringify({}));
    let dealData={
      advance_payment: 0,
      base_module_student_id: "",
      category_id: 2,
      color: "#FF0000",
      created_at: "",
      id: '',
      status: "newRequest",
      student_requirments: "",
      tution_type: "",
  }
    switch(newFormType){
      case "School":dealData.category_id=1;break;
      case "/new-competitive-exams-request":dealData.category_id=3;break;
      case "/new-Colleges-Education-request":dealData.category_id=4;break;
      case "/new-abroad-exam-request":dealData.category_id=5;break;
      case "/new-learn-language-request":dealData.category_id=6;break;
      case "/new-IT-Courses-request":dealData.category_id=7;break;
      case "/new-special-kid-request":dealData.category_id=13;break;
      default:
    }
    localStorage.setItem("dealData", JSON.stringify(dealData));
    navigate('/deal-details');
    setShowPopUp(false)
  }

  useEffect(()=>{
    setShowPopUp(()=>true)
    window.scrollTo({top: 0,behavior: 'smooth'});
    logEvent(EVENTS.ACADEMY_CLICKED.event_name)
  },[])


  return (
    <main className='main'>

          {(showPopUP)&&<div className=''>
            <div className='tutor-popup-wrapper--transparent-screen' style={{zIndex:80}} onClick={()=>{setShowPopUp(false)}}></div>
            <div className='tutor-popup-wrapper--content-screen' style={{zIndex:81}}>
                  <div onClick={()=>{goTo('School');setShowPopUp(false)}} className='close-tutor-popup-btn'> Close </div>
                  <form className='tutor-hire--popup--wrapper'>
                    <div style={{height:"auto"}}>
                      <div style={{fontWeight:"600"}} >Select Your Domain of Course</div>

                      <select  style={{margin:"20px",height:"35px",width:"80%"}}  onChange={(e)=>{
                        let val=e.target.value
                        goTo(val)
                      }}>
                          <option value="" hidden> Select Value </option>
                          <option value="School" >School Education  </option>
                          <option value="/new-special-kid-request" >Special Kids (ADHD /ADD /AUTISM / Dyslexia) </option>
                          <option value="/new-learn-language-request" >Learn Language  </option>
                          <option value="/new-competitive-exams-request" > Competiteve Exams</option>
                          <option value="/new-IT-Courses-request" >IT Courses  </option>
                          {/* <option value="/new-Colleges-Education-request" >Colleges Education</option> */}
                          {/* <option value="/new-abroad-exam-request" >Abroad Exams  </option> */}
                      </select>
                      
                    </div>
                  </form>
            </div>
        </div>}

        <div>
          <section className='pathway-requestBtn--wrapper'>
            <div className='pathway--wrapper'>
              <div><img src="/homeicon.svg" alt="homeicon icon" width="18" height="17" /></div>
              <div><img src="/rightArrow.svg" alt="rightArrow icon" width="11" height="11" /></div>
              <div className='pathway--btn'><NavLink to='/dashboard'>All Requests</NavLink></div>
              <div><img src="/rightArrow.svg" alt="rightArrow icon" width="11" height="11" /></div>
              <div className='pathway--btn'> New Request</div>
            </div>
          </section>

          <section className='deal-heading--wrapper'>
            <div className='status--wrapper'>
              <div className='deal-heading--subheading'>Current Status : </div>
              <div className='deal-heading--value'> New Request</div>
            </div>
            <div className='requestId--wrapper'></div>
          </section>

          <section className='deal-info--container'>

            <div className='deal-info--container--title-wrapper'>
              <div className='deal-info--container--title'>Request Title</div>
              <div className='deal-info--container--editOption' >
              </div>
            </div>


            <div className='dealsFlow-Status--container'>
              <div className='dealFlow-icons--wrapper'>

                <div className='deal-mileStone--wrapper'>
                  <div className='deal-mileStone--icon--wrapper' style={{backgroundColor:'rgba(77, 161, 188, 0.4)'}}>
                    <div className='deal-mileStone--icon'>
                      <img src="/request-icon.svg" alt="request icon" width="30" height="30" />
                    </div>
                  </div>
                  <div className='deal-mileStone--status--wrapper' style={{backgroundColor:'white'}}>
                      <span style={{color:'#2B93A5',fontWeight:900}}>i</span>
                  </div>
                  <div className='deal-mileStone--title' style={{backgroundColor:'#2B93A5'}}>
                    Request
                  </div>
                </div>

                <div className='deal-mileStone--wrapper'>
                  <div className='deal-mileStone--icon--wrapper' style={{backgroundColor:'rgba(188, 157, 77, 0.4)'}}>
                    <div className='deal-mileStone--icon'>
                      <img src="/payment-icon.svg" alt="payment icon" width="30" height="30" />
                    </div>
                  </div>
                  <div className='deal-mileStone--status--wrapper' style={{backgroundColor:'white'}}>
                      <span style={{color:'#2B93A5',fontWeight:900}}></span>
                  </div>
                  <div className='deal-mileStone--title' style={{backgroundColor:'white',color:'black'}}>
                    Pay
                  </div>
                </div>

                <div className='deal-mileStone--wrapper'>
                  <div className='deal-mileStone--icon--wrapper' style={{backgroundColor:'rgba(188, 77, 117, 0.4)'}}>
                    <div className='deal-mileStone--icon'>
                      <img src="/demo-icon.svg" alt="demo icon" width="30" height="30" />
                    </div>
                  </div>

                  <div className='deal-mileStone--status--wrapper' style={{backgroundColor:'white'}}>
                      <span style={{color:'#2B93A5',fontWeight:900}}></span>
                  </div>
                  <div className='deal-mileStone--title' style={{backgroundColor:'white',color:'black'}} >
                    Demo
                  </div>
                </div>

                <div className='deal-mileStone--wrapper'>
                  <div className='deal-mileStone--icon--wrapper' style={{backgroundColor:'rgba(132, 188, 77, 0.4)'}}>
                    <div className='deal-mileStone--icon'>
                      <img src="/assigned-icon.svg" alt="assigned icon" width="30" height="30" />
                    </div>
                  </div>
                  <div className='deal-mileStone--status--wrapper' style={{backgroundColor:'white'}}>
                      <span style={{color:'#2B93A5',fontWeight:900}}></span>
                  </div>
                  <div className='deal-mileStone--title '  style={{backgroundColor:'white',color:'black'}} >
                    Assigned
                  </div>
                </div>
                </div>

                <div className='dealFlow-lines-wrapper'>
                  <div className='dealFlow-solid-line'></div>
                  <div className='dealFlow-dotted-line'></div>
                </div>
              </div>

            <div className='end-of-dealFlow-icons-line'></div>            

          </section>
      </div>


    </main>
  )
}
