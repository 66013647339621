import PersonalInfo from './personalInfo';
import Address from './address';
import { NavLink,useNavigate } from 'react-router-dom';
import Settings from './settings';
import React, { useEffect, useState } from 'react'
import Counselling from '../counselling';
import './myProfile.css'
import NewAddress from './newAddress';



export default function MyProfile() {

  const [activeNavigation,setActiveNavigation]=useState()
  const navigate = useNavigate();


  useEffect(()=>{
    let jwt=JSON.parse(localStorage.getItem("homeshiksha-jwt"));
    if(!jwt)navigate ('/');
    else setActiveNavigation(()=>'personal')
  },[])


  return (
    <main className='main'>
      <section className='pathway-requestBtn--wrapper'>
        <div className='pathway--wrapper'>
          <div onClick={()=>{navigate ('/dashboard')}}>
                    <img
                    src="/homeicon.svg"
                    alt="homeicon icon"
                    width="18"
                    height="17"
                    />
          </div>
          <div>
                  <img
                    src="/rightArrow.svg"
                    alt="rightArrow icon"
                    width="11"
                    height="11"
                    />
          </div>
          <div className='pathway--btn'>Account</div>
          <div>
                  <img
                    src="/rightArrow.svg"
                    alt="rightArrow icon"
                    width="11"
                    height="11"
                    />
          </div>
          <div className='pathway--btn'>{(activeNavigation=='personal')?'Personal Info':(activeNavigation=='children')?'My Children':(activeNavigation=="address")?"My Address":(activeNavigation=="settings")?"Settings":''}</div>
        </div>

      </section>
      <section className='personal-info--container'>


          <div className='personal-info--navigation--wrapper'>
            <div className='personal-info--navigation'>
              <div onClick={()=>{setActiveNavigation('personal')}} className={`${(activeNavigation=='personal')?'active-personal-info--navigation':'unactive-personal-info--navigation'}`}>Personal Info</div>
              {/* <div onClick={()=>{setActiveNavigation('children')}} className={`${(activeNavigation=='children')?'active-personal-info--navigation':'unactive-personal-info--navigation'}`}>My Children</div> */}
              <div onClick={()=>{setActiveNavigation('address')}} className={`${(activeNavigation=='address')?'active-personal-info--navigation':'unactive-personal-info--navigation'}`}>My Address</div>
              <div onClick={()=>{setActiveNavigation('Counselling')}} className={`${(activeNavigation=='Counselling')?'active-personal-info--navigation':'unactive-personal-info--navigation'}`}>Counselling</div>
              {/* <div onClick={()=>{setActiveNavigation('settings')}} className={`${(activeNavigation=='settings')?'active-personal-info--navigation':'unactive-personal-info--navigation'}`}>Settings</div> */}
              <NavLink to='/' ><div onClick={()=>{
                localStorage.removeItem("homeshiksha-jwt");
                window.location.reload();
                }} className={`${(activeNavigation=='settings')?'active-personal-info--navigation':'unactive-personal-info--navigation'}`}>Log Out</div></NavLink>
              {/* <div><NavLink to='/' >Log Out</NavLink></div> */}
            </div>
          </div>


          <div className='personal-info--content--page'>

            {(activeNavigation=='personal')?<PersonalInfo/>:<></>}
            {(activeNavigation=='children')?<div>children</div>:<></>}

            {(activeNavigation=='address')?<NewAddress />:<></>}
            {(activeNavigation=='Counselling')?<Counselling />:<></>}

            {/* {(activeNavigation=='settings')?:<></>} */}
            {(activeNavigation=='settings')?<div>settings</div>:<></>}


          </div>



      </section>
    </main>
  )
}
