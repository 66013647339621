import React, { useState } from 'react'
import Button from '../components/button'
import TutorCard from '../components/tutor-card'
import { NavLink,useNavigate } from 'react-router-dom';

export default function Tutors() {

  const options = ['Online', 'Offline', 'Any'];
  const options2 = ['Address 1', 'Address 2', 'Address 3'];

  const [data,setData]=useState([])
  const [activeContainer,setActiveContainer]=useState('Hired')
  const [showHireTutorPopUp,setShowHireTutorPopUp]=useState(false)
  const [showFireTutorPopUp,setShowFireTutorPopUp]=useState(false)
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOption2, setSelectedOption2] = useState('');
  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSelectChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };

  const hireTutor=(data)=>{
    // alert(data)
    setShowHireTutorPopUp(()=>data)
  } 
  const fireTutor=(data)=>{
      setShowFireTutorPopUp(()=>data)
  }
  const hidePopUp=()=>{
    setShowFireTutorPopUp(()=>false)
    setShowHireTutorPopUp(()=>false)
  }




  return (
    <main className='main tutor-popup-wrapper'>

        {(showHireTutorPopUp||showFireTutorPopUp)?<div className=''>
            <div className='tutor-popup-wrapper--transparent-screen' onClick={()=>hidePopUp()}></div>
            <div className='tutor-popup-wrapper--content-screen'>
                  <div onClick={()=>hidePopUp()} className='close-tutor-popup-btn'> Close </div>
                  <form className='tutor-hire--popup--wrapper'>


                    <div>
                      <div >Select Date and Time</div>
                      <input id='hireDateTime' type='datetime-local' />
                    </div>


                    <div>
                      <div >Mode </div>
                      <select id="hireDropdown" value={selectedOption} onChange={handleSelectChange}>
                        <option value=""> Select an option </option>
                        {options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>


                    <div>
                      <div>Select the Location</div>
                      <select id="hireDropdown" value={selectedOption2} onChange={handleSelectChange2}>
                        <option value=""> Select an option </option>
                        {options2.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>


                  </form>
                  <div className='popup-btn-wrappers'>
                    <div onClick={()=>hidePopUp()} className='secondary-btn'>Cancel</div>
                    <div className='primary-btn' >Start</div>
                  </div>
            </div>
        </div>:<></>}

      <section className='pathway-requestBtn--wrapper'>
        <div className='pathway--wrapper'>
          <div onClick={()=>{navigate ('/dashboard')}}>
                    <img
                    src="/homeicon.svg"
                    alt="homeicon icon"
                    width="18"
                    height="17"
                    />
          </div>
          <div>
                  <img
                    src="/rightArrow.svg"
                    alt="rightArrow icon"
                    width="11"
                    height="11"
                    />
          </div>
          <div className='pathway--btn'>Tutors</div>
        </div>
        <div className='request-btn--wrapper'>
              <NavLink to='/new-request' ><Button>New Request</Button></NavLink>
        </div>
      </section>

      <section>
        <div className='tutors--container--nav-wrapper'>
              <div  onClick={()=>{setActiveContainer(()=>'Hired')}} className={`${(activeContainer=='Hired')?'active-btn':'unactive-btn'}`} style={{width:"150px"}}>Your Hired Tutors</div>
              {/* <div  onClick={()=>{setActiveContainer(()=>'Hired')}} className={`${(activeContainer=='Hired')?'active-btn':'unactive-btn'}`}>Hired</div>
              <div className={`${(activeContainer=='Fired')?'active-btn':'unactive-btn'}`} onClick={()=>{setActiveContainer(()=>'Fired')}}>Fired</div> */}
          </div>
      </section>


      <section className='deal-info--container'>
        <div  className='assigned-tutors--container'>
          <TutorCard data={{status:activeContainer}} onClick={hireTutor} />
          
        </div>
      </section>

    </main>
  )
}
