import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { logEvent } from '../analytics';
export default function DealRequestCard(props) {
    const [dealData,setDealData]=useState({})
    const [isMobile, setIsMobile] = useState(false)

    const moveToDealDetailsPage=(e)=>{
        localStorage.setItem("dealData", JSON.stringify({}));
        localStorage.setItem("dealData", JSON.stringify(e));
    }
    const makeTitle=(string)=>string.charAt(0).toUpperCase() + string.slice(1);


    useEffect(()=>{
        setIsMobile(window.innerWidth <= 768);
        setDealData((pre)=>{
            let newData=props.data
            switch (newData.status){
                case 'assigned':newData.color='#22A50D';break;
                case 'demo':newData.color='#FF9900';break;
                case 'pending':newData.color=(!newData.advance_payment)?'#FF0000':"#7ba8e4";break;
                default:
            }
            return newData
        })
    },[])

  return (
    (dealData.id)&&
    <NavLink to='/deal-details' onClick={()=>{moveToDealDetailsPage(dealData)}}>
        <div className='deal-request-card--wrapper' style={{borderLeftColor:dealData.color}} >
            <div className='deal-request-card--top-part'>
                <div className='deal-request-card--top-part--left'>
                    <img src={`/${dealData.status}-card-icon.svg`} alt="Bell Notification" width={`${(!isMobile)?"35":"25"}`} height={`${(!isMobile)?"35":"25"}`} />
                    <div style={{color:'#bebcbc'}}>{dealData.tution_type}</div>
                </div>
                <div className='deal-request-card--top-part--right'>
                    <div className='deal-request-card--light' style={{backgroundColor:dealData.color,boxShadow:`0px 0px 5px ${dealData.color}`}}></div>
                    <div className='deal-request-card--status' style={{color:dealData.color,fontWeight:"600"}}>{makeTitle((dealData.advance_payment&&dealData.status==="pending")?"In Progress":dealData.status)}</div>
                </div>
            </div>
            <div className='deal-request-card--mid-part'>
                {dealData.created_at}
            </div>
            <div className='deal-request-card--bottom-part'>
                <div><span style={{color:"rgba(130, 130, 130, 1)",fontSize:"16px",fontWeight:"500"}}>Subjects : </span>{(isMobile)?dealData.student_requirments.substring(0,25):dealData.student_requirments.substring(0,50)}{((dealData.student_requirments.length>25&&isMobile)||(dealData.student_requirments.length>50&&!isMobile))&&'...'} </div>
                <div style={{textDecoration:"underline"}}>View</div>
            </div>
        </div>
    </NavLink>
    
  )
}
