import React, { useEffect, useState } from "react";
import axios from "../axios";
import DemoCard from "./demo-card";

export default function DemoTutors() {
  const [isMobile, setIsMobile] = useState(false);
  const [activeContainer, setActiveContainer] = useState();
  const [tutorsData, setTutorsData] = useState([]);
  let _dealData = JSON.parse(localStorage.getItem("dealData"));
  useEffect(() => {
    let dealId = _dealData.id;
    axios
      .get(`v1/student/tutors-for-deals`, {
        params: {
          dealId: dealId,
        },
      })
      .then((response) => {
        setActiveContainer(() => "Demo");
        if (response.data.tutorsData) {
          setTutorsData(() =>
            response.data.tutorsData.reduce((pre, data) => {
              return [...pre, ...data.tutors];
            }, [])
          );
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    setIsMobile(() => window.innerWidth <= 768);
  }, []);

  const makeTitle = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return tutorsData.length != 0 ? (
    <div className="demo-tutors--container">
      {!isMobile && (
        <div className="demo-tutors--container--nav-wrapper">
          {/* <div  onClick={()=>{setActiveContainer(()=>'Demo')}} className={`${(activeContainer=='Demo')?'active-btn':'unactive-btn'}`}>Assigned Tutor For Demo</div> */}
          {/* <div  onClick={()=>{setActiveContainer(()=>'Demo')}} className={`${(activeContainer=='Demo')?'active-btn':'unactive-btn'}`}>Demo</div> */}
          {/* <div className={`${(activeContainer=='Tried')?'active-btn':'unactive-btn'}`} onClick={()=>{setActiveContainer(()=>'Tried')}}>Tried</div>
            <div className={`${(activeContainer=='Shortlisted')?'active-btn':'unactive-btn'}`} onClick={()=>{setActiveContainer(()=>'Shortlisted')}}>Shortlisted</div> */}
        </div>
      )}

      <div className="demo-tutors--cards--wrapper">
        {tutorsData.map((data, index, array) => {
          return makeTitle(data.status) === activeContainer || isMobile ? (
            <DemoCard key={data.id} data={data} />
          ) : null;
        })}
      </div>
    </div>
  ) : (
    <></>
  );
}
