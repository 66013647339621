import React, { useEffect, useState } from "react";
import axios from "../../axios";
import imageCompression from "browser-image-compression";
import { logEvent } from "../../analytics";
import { EVENTS } from "../../constants/ga-constants";

export default function PersonalInfo() {
  const [originalPersonalInfo, setOriginalPersonalInfo] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [editRequest, setEditRequest] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [DPLoad, setDPLoad] = useState(false);

  const updateUserDetails = (_newData) => {
    let address = {
      id: _newData.id,
      first_name: _newData.name,
      last_name: _newData.surname,
      phone: _newData.mobileNo,
      phone2: _newData.alterMobileNo,
      email: _newData.emailId,
      flat: _newData.flat,
      apartment: _newData.apartment,
      street: _newData.street,
      locality: _newData.locality,
      city_name: _newData.city,
      state_name: _newData.state,
      zip: _newData.pincode,
      latitude: _newData.latitude,
      longitude: _newData.longitude,
    };
    axios
      .post(`v1/updateUserDetails`, address)
      .then((data) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    let _userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(() => ({
      profilePic: _userDetails.profilePic,
      Name: _userDetails.Name,
    }));

    axios
      .get(`v1/user`)
      .then((data) => {
        data = data.data.data;
        data = {
          id: data.id,
          name: data.first_name,
          surname:
            data.last_name != null && data.last_name != "undefined"
              ? data.last_name
              : "",
          flat: data.flat != null && data.flat != "undefined" ? data.flat : "",
          apartment:
            data.apartment != null && data.apartment != "undefined"
              ? data.apartment
              : "",
          street:
            data.street != null && data.street != "undefined"
              ? data.street
              : "",
          mobileNo:
            data.phone != null && data.phone != "undefined" ? data.phone : "",
          alterMobileNo:
            data.phone2 != null && data.phone2 != "undefined"
              ? data.phone2
              : "",
          emailId:
            data.email != "null" && data.email != "undefined" ? data.email : "",
          state:
            data.state_name != null && data.state_name != "undefined"
              ? data.state_name
              : "",
          city:
            data.city_name != null && data.city_name != "undefined"
              ? data.city_name
              : "",
          locality:
            data.locality != "undefined" && data.locality != null
              ? data.locality
              : "",
          pincode: data.zip != null && data.zip != "undefined" ? data.zip : "",
          latitude:
            data.latitude != null && data.latitude != "undefined"
              ? data.latitude
              : "",
          longitude:
            data.longitude != null && data.longitude != "undefined"
              ? data.longitude
              : "",
          dob: "--/--/----",
        };
        // // console.log(data);
        setPersonalInfo(() => data);
        setOriginalPersonalInfo(() => data);
        setLoading(() => false);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalInfo((pre) => ({
      ...pre,
      [name]: value.replace('"', "'"),
    }));
  };

  const uploadedProfilePic = async (e) => {
    try {
      let uploadedFile = e.target.files[0];
      if (uploadedFile && uploadedFile.size <= 5 * 1024 * 1024) {
        setDPLoad(true);
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
        };
        let response = await imageCompression(e.target.files[0], options);

        uploadedFile = new File([response], e.target.files[0].name, {
          lastModified: e.target.files[0].lastModified,
          type: e.target.files[0].type,
        });

        const formData = new FormData();
        formData.append("myImage", uploadedFile);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        axios
          .post(`v1/profilePic`, formData, config)
          .then((response) => {
            setDPLoad(false);
            setUserDetails((pre) => {
              return { ...pre, profilePic: response.data.imageUrl };
            });
            logEvent(EVENTS.PROFILE_PIC_UPLOAD.event_name)
          })
          .catch((error) => {
            setDPLoad(false);
            alert("Falied to Upload Profile Pic");
          });
      } else {
        alert("Image size should be less than or equal to 5MB");
      }
    } catch (error) {
      alert("Falied to Upload Profile Pic");
    }
  };

  const onInputClick = (event) => {
    event.target.value = null;
  };

  return personalInfo && !loading ? (
    <form
      className="personal-info--personal-content--wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setOriginalPersonalInfo(() => personalInfo);
        updateUserDetails(personalInfo);
        setEditRequest(() => false);
      }}
    >
      <div className="personal-info--personal-content-1">
        <div className="personal-info--personal-content-1-left">
          <div className="personal-info--personal-content-profilePic-wrapper">
            <div className="tutor-profilePic--wrapper">
              <img
                src={
                  userDetails.profilePic != "null"
                    ? userDetails.profilePic
                    : "./person-icon.png"
                }
                alt="user profile pic"
                width={window.innerWidth <= 768 ? "60" : "100"}
              />
            </div>
            <div className="showRequest--Edit-btn">
              {personalInfo.mobileNo && !DPLoad ? (
                <form>
                  <label htmlFor="profilePic-1">File Upload</label>
                  <input
                    id="profilePic-1"
                    type="file"
                    name="myImage"
                    onChange={uploadedProfilePic}
                    onClick={onInputClick}
                    accept=".jpg, .jpeg, .png, .gif"
                    style={{ display: "none" }}
                  />
                </form>
              ) : (
                <form>
                  <label>Uploading</label>
                </form>
              )}
            </div>
          </div>
          <div className="personal-info--personal-content-profile-name">
            {`Hi, ${personalInfo.name} ${personalInfo.surname}`}
          </div>
        </div>
        <div className="personal-info--personal-content-1-right">
          {!editRequest ? (
            <div
              onClick={() => {
                setEditRequest(() => true);
              }}
              style={{ cursor: "pointer", width: "50px", margin: "0 0 0 auto" }}
            >
              <span className="edit-txt">Edit</span>
              <img
                src="/pencil-icon.svg"
                alt="pencil-icon"
                width="15"
                height="15"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={`personal-info--personal-content-2 ${
          editRequest ? "editInput" : ""
        }`}
      >
        <div className="personal-info--personal-content--field-wrapper">
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            Full Name
          </div>
          <div className="personal-info--personal-content-data">
            <input
              type="text"
              readOnly={!editRequest}
              name="name"
              onChange={handleInputChange}
              value={personalInfo.name}
            />
            <input
              type="text"
              readOnly={!editRequest}
              name="surname"
              onChange={handleInputChange}
              value={personalInfo.surname}
            />
          </div>
        </div>
        <div className="personal-info--personal-content--field-wrapper">
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            Mobile No
          </div>
          <div className="personal-info--personal-content-data">
            <input
              type="text"
              readOnly={editRequest}
              name="mobileNo"
              onChange={handleInputChange}
              value={personalInfo.mobileNo}
            />
          </div>
        </div>
        <div className="personal-info--personal-content--field-wrapper">
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            Alter Mobile No
          </div>
          <div className="personal-info--personal-content-data">
            <input
              type="text"
              readOnly={!editRequest}
              name="alterMobileNo"
              onChange={handleInputChange}
              value={personalInfo.alterMobileNo}
            />
          </div>
        </div>
        <div className="personal-info--personal-content--field-wrapper">
          <div className="personal-info--personal-content-subtitle">
            <img
              src="/user-profile-focus.svg"
              alt="user-profile-focus"
              width="15"
              height="15"
            />
            Email Id{" "}
          </div>
          <div className="personal-info--personal-content-data">
            <input
              type="email"
              readOnly={!editRequest}
              name="emailId"
              onChange={handleInputChange}
              value={personalInfo.emailId}
            />
          </div>
        </div>
        {/* <div className='personal-info--personal-content--field-wrapper'>
                        <div className='personal-info--personal-content-subtitle'>
                          <img src="/user-profile-focus.svg" alt="user-profile-focus" width="15" height="15"/>
                          Date Of Birth</div>
                        <div className='personal-info--personal-content-data'>
                          <input type='text' readOnly={!editRequest} name='dob' onChange={handleInputChange} value= {personalInfo.dob}/>
                          </div>
                    </div> */}
      </div>

      {editRequest ? (
        <div className="showRequest-Save-cancel-wrapper">
          <div
            onClick={() => {
              setPersonalInfo(() => originalPersonalInfo);
              setEditRequest(() => false);
            }}
            className="showRequest-Save-wrapper showRequest-cancel-wrapper"
          >
            <button type="button">cancel</button>
          </div>
          <div className="showRequest-Save-wrapper">
            <button type="submit">Save</button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </form>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div className="loading">
        <img src="./book-loader.gif" alt=" Loading . . .  " />
      </div>
    </div>
  );
}
