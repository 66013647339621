import React, { useEffect, useState } from "react";
import AcademyRequest from "./academy-request";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../analytics";
import { EVENTS } from "../../constants/ga-constants";

export default function NewRequest() {
  const [formType, setFormType] = useState("academy");
  const [showPopUP, setShowPopUp] = useState(false);
  const navigate = useNavigate();

  const hidepopUP = () => {
    setShowPopUp(false);
  };
  useEffect(() => {
    logEvent(EVENTS.NEW_REQUESTED_CLICKED.event_name)
    setShowPopUp(() => true);
  }, []);

  const goTo = (newFormType) => {
    setFormType(newFormType);
    localStorage.setItem("dealData", JSON.stringify({}));
    localStorage.setItem(
      "dealData",
      JSON.stringify({
        advance_payment: 0,
        base_module_student_id: "",
        category_id: 2,
        color: "#FF0000",
        created_at: "",
        id: "",
        status: "newRequest",
        student_requirments: "",
        tution_type: "",
      })
    );
    hidepopUP();
    navigate("/deal-details");
  };

  return (
    <main className="">
      {showPopUP && (
        <div className="">
          <div
            className="tutor-popup-wrapper--transparent-screen"
            onClick={() => {
              setFormType("academy");
              hidepopUP();
            }}
          ></div>
          <div className="tutor-popup-wrapper--content-screen">
            <div
              onClick={() => {
                setFormType("academy");
                hidepopUP();
              }}
              className="close-tutor-popup-btn"
            >
              {" "}
              Close{" "}
            </div>
            <form className="tutor-hire--popup--wrapper">
              <div>
                <div style={{ fontWeight: "600" }}>
                  Select Your Domain Type{" "}
                </div>

                <div className="formtype-select-options-wrapper">
                  <div
                    className="formtype-select-option-2"
                    onClick={() => {
                      setFormType("academy");
                      hidepopUP();
                    }}
                  >
                    <img
                      src="/academy-icon.svg"
                      alt="academy icon"
                      width="50"
                    />{" "}
                    <span
                      style={{
                        color: "rgba(53, 53, 53, 1)",
                        margin: "0 5px",
                        fontWeight: "600",
                      }}
                    >
                      Academy
                    </span>
                  </div>

                  <div
                    className="formtype-select-option-1"
                    onClick={() => {
                      goTo("curricular");
                    }}
                  >
                    <img
                      src="/curricular-icon.svg"
                      alt="Curricular icon"
                      width="50"
                    />{" "}
                    <span
                      style={{
                        color: "rgba(53, 53, 53, 1)",
                        margin: "0 5px",
                        fontWeight: "600",
                      }}
                    >
                      Curricular
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {formType === "academy" && <AcademyRequest />}
    </main>
  );
}
