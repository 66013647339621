import React, { useEffect, useState } from "react";
import { logEvent } from "../../analytics";
import { EditOutlined } from "@ant-design/icons";
import { EVENTS } from "../../constants/ga-constants";
import { Button, Card, Divider, notification, Space, Spin } from "antd";
import AddressMap from "./showMap";
import UpdateAddress from "./updateAddress";
import axios from "../../axios";

const EditComponent = ({ handleClick }) => {
  return (
    <Button
      type="primary"
      icon={<EditOutlined />}
      onClick={handleClick}
      iconPosition="start"
      style={{background:"#274178"}}
    >
      Edit
    </Button>
  );
};

export default function NewAddress() {
  const [address, setAddress] = useState({});
  const [editRequest, setEditRequest] = useState(false); // Start in view mode

  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, message, desc) => {
    api[type]({
      message: message,
      description: desc,
      placement: "topRight", // Optional: Customize the placement
    });
  };

  const getPersonalDetails = () => {
    axios
      .get(`v1/user`)
      .then((data) => {
        data = data.data.data;
        // // console.log(data);
        data = {
          id: data.id,
          name: data.first_name,
          surname:
            data.last_name != "undefined" && data.last_name != null
              ? data.last_name
              : "",
          flat: data.flat != "undefined" && data.flat != null ? data.flat : "",
          apartment:
            data.apartment != "undefined" && data.apartment != null
              ? data.apartment
              : "",
          street:
            data.street != "undefined" && data.street != null
              ? data.street
              : "",
          mobileNo:
            data.phone != "undefined" && data.phone != null ? data.phone : "",
          alterMobileNo:
            data.phone2 != "undefined" && data.phone2 != null
              ? data.phone2
              : "",
          emailId:
            data.email != "undefined" && data.email != null ? data.email : "",
          state:
            data.state_name != "undefined" && data.state_name != null
              ? data.state_name
              : "",
          city:
            data.city_name != "undefined" && data.city_name != null
              ? data.city_name
              : "",
          locality:
            data.locality != "undefined" && data.locality != null
              ? data.locality
              : "",
          pincode: data.zip != "undefined" && data.zip != null ? data.zip : "",
          latitude:
            data.latitude != "undefined" && data.latitude != null
              ? data.latitude
              : "",
          longitude:
            data.longitude != "undefined" && data.longitude != null
              ? data.longitude
              : "",
        };
        setAddress(() => data);
        setCoordinates({
          lat: data.latitude,
          lng: data.longitude,
        });
        setLoading(() => false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (Object.keys(address).length === 0) {
      getPersonalDetails();
    } else {
      if(!address.latitude || !address.longitude){
        setEditRequest(true)
      }
      setLoading(false);
    }
  }, []);

  const updateUserAddress = (_newData) => {
    let newAddressData = {
      id: _newData.id,
      first_name: _newData.name,
      last_name: _newData.surname,
      phone: _newData.mobileNo,
      phone2: _newData.alterMobileNo,
      email: _newData.emailId,
      flat: _newData.flat,
      apartment: _newData.apartment,
      street: _newData.street,
      city_name: _newData.city,
      locality: _newData.locality,
      gmap_meta_data: _newData.gmap_meta_data,
      state_name: _newData.state,
      zip: _newData.pincode,
      latitude: _newData.latitude,
      longitude: _newData.longitude,
    };

    axios
      .post(`v1/updateUserDetails`, newAddressData)
      .then((data) => {
        getPersonalDetails()
        logEvent(EVENTS.UPDATED_ADDRESS.event_name);
      })
      .catch((err) => {});
  };

  return (
    <>
      {address && !loading ? (
        !editRequest ? (
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Card
              title="Address"
              size="default"
              extra={<EditComponent handleClick={() => setEditRequest(true)} />}
            >
              <p>
                {address?.flat +
                  " " +
                  address?.apartment +
                  " " +
                  address?.street +
                  " " +
                  address?.city +
                  " " +
                  address?.state +
                  " " +
                  address?.pincode}
              </p>
              <Divider />
              {(address && address?.latitude || address?.longitude) && <AddressMap mapSize={"100%"} coordinates={coordinates} />}
            </Card>
          </Space>
        ) : (
          <UpdateAddress
            address={address}
            onEditClick={() => setEditRequest(false)}
            setCoordinates={setCoordinates}
            coordinates={coordinates}
            setAddress={setAddress}
            updateUserAddress={updateUserAddress}
          />
        )
      ) : (
        <Spin size="large" />
      )}
    </>
  );
}
