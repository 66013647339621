import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import "./App.css";
import Navigation from "./webpages";
import Dashboard from "./webpages/dashboard";
import Attendance from "./webpages/attendance";
import Assignment from "./webpages/assignment";
import Tutors from "./webpages/tutors";
import Payments from "./webpages/payments";
import DealDetails from "./webpages/deal-details";
import axios from "./axios";
import MyProfile from "./webpages/myProfile/myProfile";
import PersonalInfo from "./webpages/myProfile/personalInfo";
import Address from "./webpages/myProfile/address";
import Settings from "./webpages/myProfile/settings";
import NewRequest from "./webpages/NewRequest/new-request";
import AcademyRequest from "./webpages/NewRequest/academy-request";
import LoginPage from "./webpages/login-page";
import NeedATutor from "./need-a-tutor";
import WaLogin from "./webpages/waLogin";
import Counselling from "./webpages/counselling";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import NewAddress from "./webpages/myProfile/newAddress";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);
  const [showNavBar, setshowNavBar] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title:"Home Page"
    });
    if (JSON.parse(localStorage.getItem("homeshiksha-jwt"))) {
      getDashboardDetails();
      setshowNavBar(true);
    } else {
      setshowNavBar(false);
      if (["/", "/wa-login"].includes(window.location.pathname)) {
        setShowLogin(true);
        setshowNavBar(false);
      } else setShowLogin(false);
    }

    setIsMobile(window.innerWidth <= 768);
  }, []);

  const getDashboardDetails = () => {
    axios
      .get(`v1/student/dashboard`)
      .then((data) => {
        const response = data.data;
        setUserDetails({
          profilePic: response.profile_pic,
          Name: response.user_name,
          userId: response.id,
        });
      })
      .catch((err) => {
        setUserDetails({ profilePic: null });
      });
  };

  return (
    <div className="App">
      <Router>
        <header>
          {showMobileNavigation && (
            <div className="mobile-navigation-wrapper">
              <div>
                <div className="mobile-navigation-top-bar">
                  <div className="unactive-link">
                    <NavLink to={process.env.REACT_APP_OLD_HOMESHIKSHA}>
                      <img src="/homeshiksha-logo.jpg" alt="logo" width="30" />
                    </NavLink>
                  </div>
                  <div
                    className="mobile-navigation-close unactive-link"
                    onClick={() => {
                      setShowMobileNavigation(() => false);
                    }}
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    close
                  </div>
                </div>
                <div
                  className="mobile-navigation-content-wrapper"
                  onClick={() => {
                    setShowMobileNavigation(() => false);
                  }}
                >
                  <Navigation />
                </div>
              </div>

              <div className="mobile-navigation-bottom-wrapper">
                {/* <div>
                <div style={{"textDecoration":"underline",color:"rgba(255, 71, 31, 1)"}}>Delete My Account</div>
                <div style={{"textDecoration":"underline",color:"rgba(43, 147, 165, 1)"}}>Become a Tutor</div>
              </div> */}
                <div
                  className="primary-btn"
                  onClick={() => {
                    setShowMobileNavigation(() => false);
                    // window.location.reload();
                  }}
                >
                  <NavLink to="/">
                    <div
                      onClick={() => {
                        localStorage.removeItem("homeshiksha-jwt");
                        window.location.reload();
                      }}
                    >
                      Logout
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          )}

          <div className="top-bar">
            <section className="logo">
              {" "}
              <NavLink to={process.env.REACT_APP_OLD_HOMESHIKSHA}>
                <img src="/homeshiksha-logo.jpg" alt="logo" width="50" />
              </NavLink>
            </section>

            {!isMobile && <Navigation />}

            {showNavBar && (
              <section className="top-bar--profile-wrapper">
                <div>
                  <img
                    src="/bell-notification.svg"
                    alt="Bell Notification"
                    width="20"
                    height="20"
                  />
                </div>

                {!isMobile ? (
                  <NavLink to="/my-profile">
                    <div className="profilePic--wrapper">
                      <img
                        src={
                          userDetails.profilePic != undefined &&
                          userDetails.profilePic != "null"
                            ? userDetails.profilePic
                            : "./person-icon.png"
                        }
                        alt="user profile pic"
                        height="50"
                      />
                    </div>
                  </NavLink>
                ) : (
                  !showMobileNavigation && (
                    <div
                      className="profile-page--wrapper"
                      onClick={() => {
                        setShowMobileNavigation((pre) => !pre);
                      }}
                    >
                      <img
                        src="/profile-nav-mobile-icon.svg"
                        alt="user profile pic"
                        width="20"
                        height="20"
                      />
                    </div>
                  )
                )}
              </section>
            )}

            {!showNavBar && (
              <div>
                {!showLogin ? (
                  <section style={{ color: "black", fontWeight: "500" }}>
                    <NavLink
                      to="/"
                      onClick={() => {
                        setShowLogin(() => true);
                      }}
                      className="top-bar-btns"
                    >
                      {" "}
                      Login
                    </NavLink>
                  </section>
                ) : (
                  <section style={{ color: "black", fontWeight: "500" }}>
                    <NavLink
                      to="/need-a-tutor"
                      onClick={() => {
                        setShowLogin(() => false);
                      }}
                      className="top-bar-btns"
                    >
                      {" "}
                      Register
                    </NavLink>
                  </section>
                )}
              </div>
            )}
          </div>
        </header>

        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={<Dashboard setshowNavBar={setshowNavBar} />}
          />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/assignment" element={<Assignment />} />
          <Route path="/tutors" element={<Tutors />} />
          <Route path="/counselling" element={<Counselling />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/deal-details" element={<DealDetails />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/personal-info" element={<PersonalInfo />} />
          <Route path="/address" element={<NewAddress />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/new-request" element={<NewRequest />} />
          <Route path="/new-academy-request" element={<AcademyRequest />} />
          <Route path="/need-a-tutor" element={<NeedATutor />} />
          <Route path="/wa-login/:randomString" element={<WaLogin />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
