import React, { useEffect, useState } from 'react'
import './deal-details.css'
import Button from '../components/button';
import DemoTutors from '../components/demo-tutors';
import TutorCard from '../components/tutor-card'
import SecondaryButton from '../components/secondary-button'
import { NavLink,useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from '../axios';

import { 
  commonFormTemplate,
  academyFormTemplate,
  curricularFormTemplate,
  competitiveExamsFormTemplate,
  intermediateFormTemplate,
  abroadExamsFormTemplate,
  learnLanguageFormTemplate,
  specialKidsFormTemplate,
  iTCoursesFormTemplate,
  genderConversion,
  modeOfTutionconversion
 } from '../constants/form-templates';


export default function DealDetails() {

  const [data,setData]=useState({})
  const [showRequest,setShowRequest]=useState(false)
  const [editRequest,setEditRequest]=useState(false)
  const [showPending,setShowPending]=useState(false)
  const [showDemo,setShowDemo]=useState(false)
  const [showAssigned,setShowAssigned]=useState(false)
  const [Form,setForm]=useState()
  const [streamsData,setStreamsData]=useState([])
  const [loading,setLoading]=useState(true)
  const navigate = useNavigate();
  const [activePayNow,setActivePayNow]=useState(false)


  const reSetAll=()=>{
    setShowRequest(false)
    setEditRequest(false)
    setShowPending(false)
    setShowDemo(false)
    setShowAssigned(false)
  }

  const _Request=(id)=>{
    if(!['newRequest'].includes(data.status)){
      reSetAll();
      setShowRequest(true);
    }
  }

  const _Pay=()=>{
    if(!['newRequest'].includes(data.status)){
      reSetAll();
      setShowPending(true)
    }
  }

  const _Demo=()=>{
    if(['assigned','demo'].includes(data.status)){
      reSetAll();
      setShowDemo(true)
    }
  }

  const _Assigned=()=>{
    if(['assigned'].includes(data.status)){
      reSetAll();
      setShowAssigned(true)
    }
  }

  const _Next=()=>{
    if(showRequest)_Pay();
    else if(showPending)_Demo();
    else if(showDemo)_Assigned();
  }

  const _Previous=()=>{
    if(showAssigned)_Demo();
    else if(showDemo)_Pay();
    else if(showPending)_Request(data.id);
  }


  const postNewDeal=(formData)=>{

    const tution_type=modeOfTutionconversion[formData.find((input)=>input.inputName==="mode_of_tution").value]
    const tutor_gender_preference=genderConversion[formData.find((input)=>input.inputName==="tutor_gender").value]
    let newDeal={
        "dealId":data.id,
        "student_requirments":formData.find((input)=>input.inputName==="course_name").value.split(','),
        "level":(data.category_id===1||data.category_id===4)?formData.find((input)=>input.inputName==="course_level").value:"Advanced Level",
        tutor_gender_preference,
        "how_many_days_in_a_week":formData.find((input)=>input.inputName==="no_of_days_per_week_required").value,
        "when_you_like_to_start":formData.find((input)=>input.inputName==="when_to_start_class").value,
        tution_type,
        "stream_id":formData.find((input)=>input.inputName==="stream_board").value,
        "category_id":data.category_id,
        "base_module_student_id":data.base_module_student_id
    }
    axios.post(`v1/student/new-deal`,newDeal).then((response)=>{
        navigate('/dashboard')
    }).catch((err)=>{
        // console.log(err);
    })
  }


  const getDealFullDetails=(dealId,Form,streamsData)=>{
    axios.get(`v1/student/deal-info?dealId=${dealId}`).then((res)=>{
        res=res.data;
        let restltForm=[];
        let _courseList;
        restltForm=Form.map((itm)=>{
          switch(itm.inputName){
            case "stream_board":
                  _courseList=streamsData.find((it)=>it.stream_name.toLowerCase()===res.stream_id.toLowerCase())
                  itm= {...itm,"value":res.stream_id}
                  break;
            case "course_name":itm= {...itm,"value":res.student_requirments,"options":_courseList.courses};break;                
            case "tutor_gender":itm= {...itm,"value":genderConversion[res.tutor_gender_preference]};break;                
            case "no_of_days_per_week_required":itm= {...itm,"value":res.how_many_days_in_a_week};break;                
            case "course_level":itm= {...itm,"value":res.level};break;                
            case "when_to_start_class":itm= {...itm,"value":res.when_you_like_to_start};break;                
            case "mode_of_tution":itm= {...itm,"value":modeOfTutionconversion[res.tution_type]};break;                
            default: itm= {...itm}
          }
          return itm;
        })
        setForm(restltForm)
        setLoading(false)
        
    }).catch((err)=>{
      // console.log(err);
    })
    
  }

  const fetchCourses=(_dealData,courseLevel)=>{
    let dataFromAPI,_streamsList;
    axios.get('v1/student/all-courses?category_id='+_dealData.category_id)
    .then((response)=>
    {
          dataFromAPI=response.data
          setStreamsData(dataFromAPI)
          _streamsList=dataFromAPI.map((itm)=>itm.stream_name)
          let activeForm;
          switch(_dealData.category_id){
            case 1:activeForm=academyFormTemplate
                    break;
            case 2:activeForm=curricularFormTemplate
                    break;
            case 3:activeForm=competitiveExamsFormTemplate
                    break;
            case 4:activeForm=intermediateFormTemplate
                    break;
            case 5:activeForm=abroadExamsFormTemplate
                    break;
            case 6:activeForm=learnLanguageFormTemplate
                    break;
            case 7:activeForm=iTCoursesFormTemplate
                    break;
            case 13:activeForm=specialKidsFormTemplate
                    break;
            default:activeForm=academyFormTemplate;
          }
          activeForm=activeForm.map((formInput)=>{
                      if(formInput.inputName==="stream_board")return ({...formInput,"options":_streamsList})
                      else if(formInput.inputName==="course_name")return( {...formInput,"options":dataFromAPI[0].courses})
                      else if(formInput.inputName==="course_level"&&courseLevel!=='')return( {...formInput,value:courseLevel})
                    return formInput
                })
          activeForm=[...activeForm,...commonFormTemplate]
          setForm(activeForm)
          if(_dealData.status!=="newRequest") getDealFullDetails(_dealData.id,activeForm,dataFromAPI)
          else  setLoading(false)
        
    }).catch((err)=>{// console.log(err)
    })
    setData(_dealData);
  }

  const payNow=()=>{
    axios.post('v1/subscribe-to-plan')
    .then((response)=>{
      const requestData=response.data
      // // console.log(requestData.data);
      if(requestData.data.includes('https:')){
        setTimeout(() => {
          window.open(requestData.data, '_blank');
        })
      }
    })
    .catch((err)=>{
      // console.log(err);
    })
  }

  const refreshPageData=async()=>{
    try {
      let dealId=data.id
      
      let response=await axios.get(`v1/student/dashboard`)
      response=response.data?.requests_data?.find((deal)=>deal.id===dealId)
      
      switch(response.status){
        case 'newRequest':setShowRequest(true);setEditRequest(true);break;
        case 'pending':setShowPending(true);break;
        case 'demo':setShowDemo(true);break;
        case 'assigned':setShowAssigned(true);break;
        default: 
      }
      
      fetchCourses(response)
      localStorage.setItem("dealData", JSON.stringify(response));

    } catch (error) {}
  }

  const loadPageData=()=>{
    let _dealData=JSON.parse(localStorage.getItem("dealData"))
    switch(_dealData.status){
      case 'newRequest':setShowRequest(true);setEditRequest(true);break;
      case 'pending':setShowPending(true);break;
      case 'demo':setShowDemo(true);break;
      case 'assigned':setShowAssigned(true);break;
      default: 
    }

    fetchCourses(_dealData)
  }

  useEffect(()=>{
    refreshPageData()
  },[activePayNow])

  useEffect(() => {
    loadPageData()
  }, [])


  
  
  return (
    (!loading)?
      <main className='main'>

      <section className='pathway-requestBtn--wrapper'>
        <div className='pathway--wrapper'>
          <div>  <img src="/homeicon.svg" alt="homeicon icon" width="18" height="17" /> </div>
          <div> <img src="/rightArrow.svg" alt="rightArrow icon" width="11" height="11" /></div>
          <div className='pathway--btn'><NavLink to='/dashboard'>All Requests</NavLink></div>
          <div><img src="/rightArrow.svg" alt="rightArrow icon" width="11" height="11" /></div>
          <div className='pathway--btn'> Request {data.id}</div>
        </div>
        <div className='request-btn--wrapper'>
          <NavLink to='/new-request' ><Button>New Request</Button></NavLink>
        </div>
      </section>


      <section className='deal-heading--wrapper'>
        <div className='status--wrapper'>
          <div className='deal-heading--subheading'>Current Status : </div>
          <div className='deal-heading--value'> {(data.advance_payment&&data.status==="pending")?"In Progress":data.status}</div>
        </div>
        <div className='requestId--wrapper'>
          <div className='deal-heading--subheading'>Request ID : </div>
          <div className='deal-heading--subheading'>{data.id}</div>
        </div>
      </section>


      <section className='deal-info--container'>

        <div className='deal-info--container--title-wrapper'>
          <div className='deal-info--container--title'>Request Title</div>
          <div className='deal-info--container--editOption' >
            {(!data.advance_payment&&!editRequest)&&<div onClick={()=>{
              reSetAll()
              setEditRequest(()=>true)
              setShowRequest(()=>true)
            }}>
              Edit Request<img src="/pencil-icon.svg" alt="pencil icon" width="15" height="15" />
            </div>}
          </div>
        </div>

        <div className='dealsFlow-Status--container'>
          <div className='dealFlow-icons--wrapper'>
            <div className='deal-mileStone--wrapper'>
              <div className='deal-mileStone--icon--wrapper' style={{backgroundColor:'rgba(77, 161, 188, 0.4)'}}>
                <div className='deal-mileStone--icon'>
                  <img src="/request-icon.svg" alt="request icon" width="30" height="30" />
                </div>
              </div>
              <div className='deal-mileStone--status--wrapper' style={{backgroundColor:`${(['pending', 'demo', 'assigned'].includes(data.status))?'#2B93A5':'white'}`}}>
                  {(!['newRequest'].includes(data.status))?
                    <img src="/tick-icon.svg" alt="tick icon" width="14" height="12" />
                    :
                    <span style={{color:'#2B93A5',fontWeight:900}}>{(['newRequest'].includes(data.status)?'i':'')}</span>
                  }
              </div>
              <div className='deal-mileStone--title' onClick={()=>{_Request(data.id)}} style={{backgroundColor:'#2B93A5'}}>
                Request
              </div>
            </div>
            
            <div className='deal-mileStone--wrapper'>
              <div className='deal-mileStone--icon--wrapper' style={{backgroundColor:'rgba(188, 157, 77, 0.4)'}}>
                <div className='deal-mileStone--icon'>
                  <img src="/payment-icon.svg" alt="payment icon" width="30" height="30" />
                </div>
              </div>
              <div className='deal-mileStone--status--wrapper' style={{backgroundColor:`${(['demo', 'assigned'].includes(data.status)||data.advance_payment)?'#2B93A5':'white'}`}}>
                  {(['demo', 'assigned'].includes(data.status)||data.advance_payment)?
                    <img src="/tick-icon.svg" alt="tick icon" width="14" height="12" />
                    :
                    <span style={{color:'#2B93A5',fontWeight:900}}>{(['pending'].includes(data.status)?'i':'')}</span>
                  }
              </div>
              <div className='deal-mileStone--title' style={(!['newRequest'].includes(data.status))?{backgroundColor:'#2B93A5',color:'white'}:{backgroundColor:'white',color:'black'}} onClick={()=>{_Pay()}}>
                Pay
              </div>
            </div>
            
            <div className='deal-mileStone--wrapper'>
              <div className='deal-mileStone--icon--wrapper' style={{backgroundColor:'rgba(188, 77, 117, 0.4)'}}>
                <div className='deal-mileStone--icon'>
                  <img src="/demo-icon.svg" alt="demo icon" width="30" height="30" />
                </div>
              </div>

              <div className='deal-mileStone--status--wrapper' style={{backgroundColor:`${(['assigned'].includes(data.status))?'#2B93A5':'white'}`}}>
                  {(['assigned'].includes(data.status))?
                    <img src="/tick-icon.svg" alt="tick icon" width="14" height="12" />
                    :
                    <span style={{color:'#2B93A5',fontWeight:900}}>{(['demo'].includes(data.status)?'i':'')}</span>
                  }
              </div>
              <div className='deal-mileStone--title' style={(['assigned','demo'].includes(data.status))?{backgroundColor:'#2B93A5',color:'white'}:{backgroundColor:'white',color:'black'}} onClick={()=>{_Demo()}}>
                Demo
              </div>
            </div>
            
            <div className='deal-mileStone--wrapper'>
              <div className='deal-mileStone--icon--wrapper' style={{backgroundColor:'rgba(132, 188, 77, 0.4)'}}>
                <div className='deal-mileStone--icon'>
                  <img src="/assigned-icon.svg" alt="assigned icon" width="30" height="30" />
                </div>
              </div>
              <div className='deal-mileStone--status--wrapper' style={{backgroundColor:`${(['assigned'].includes(data.status))?'#2B93A5':'white'}`}}>
                  {(['assigned'].includes(data.status))?
                    <img src="/tick-icon.svg" alt="tick icon" width="14" height="12" />
                    :
                    <span style={{color:'#2B93A5',fontWeight:900}}>{(['assigned'].includes(data.status)?'i':'')}</span>
                  }
              </div>
              <div className='deal-mileStone--title '  style={(['assigned'].includes(data.status))?{backgroundColor:'#2B93A5',color:'white'}:{backgroundColor:'white',color:'black'}} onClick={()=>{_Assigned()}} >
                Assigned
              </div>
            </div>
          </div>

          <div className='dealFlow-lines-wrapper'>
            <div className='dealFlow-solid-line'></div>
            <div className='dealFlow-dotted-line'></div>
          </div>

        </div>


        {(!showAssigned)&&<div className='end-of-dealFlow-icons-line'></div>}
        {(showPending)&&<div className='pending--wrapper'>
          
                          {(data.advance_payment)?<div className='payment-pending--wrapper'>
                            <div className='payment-completed--icon-wrapper'>
                            <img src="/bigtick-icon.svg" alt="pencil icon" width="45" height="45" />
                            </div>
                            <div className='payment-completed--title'>Payment Done</div>
                            <div className='payment-completed--payment-amount'>Respective CRE will be assigned to you shortly</div>
                            {/* <div className='payment-pending--paynow-btn'><Button>Try Demo</Button></div> */}
                          </div>:<div className='payment-pending--wrapper'>
                            <div className='payment-pending--title'>Upgrade to HOMESHIKSHA PREFERRED CUSTOMER</div>
                            <div className='payment-pending--description'>*We get over 500 requirements for tutors daily. Sometimes its not possible for us to arrange tutors for everyone. If you genuinely have a requirement for a tutor, then pay this small 'refundable' fee of Rs.{data.advance_amount} and your requirement will be given first priority.</div>
                            {!activePayNow
                            ?
                            <div className='payment-pending--paynow-btn' style={{minWidth:"150px",width:"auto"}} onClick={()=>{setActivePayNow(true);payNow()}}>
                              <Button>
                                Pay Now
                              </Button>
                                 {/* To reflect Your Payment transaction */}
                            </div>
                            :
                            <div className='payment-pending--paynow-btn' style={{minWidth:"150px",width:"auto"}} onClick={()=>{setActivePayNow(false)}}>
                              <Button>
                                Click here 
                              </Button>
                                To reflect Your Payment transaction
                            </div>
                            }
                            <div className='payment-pending--payment-amount'>Advance Payment {data.advance_amount}/-</div>
                          </div>}


                          <div className='pending--process-wrapper'>
                                <div className='pending--process--title payment-pending--description'>Process : </div>
                                <div className='pending--process--steps--wrapper'>
                                  
                                  <ol>
                                    <li>Team will contact you within 1-2 working hour.</li>
                                    <li>Your requirement will be put on first priority.</li>
                                    <li>Dedicated Customer Relationship manager will be assigned to handle your requirement.</li>
                                    <li>This amount is fully refundable in case we are not able to Provide you the tutor as per your need.</li>
                                    <li>You will become our subscribed customer for 6 months. You will can avail free tutor replacements and have the option to request an unlimited number of tutors during this period.</li>
                                    {/* <li>If we have just one high quality tutor available and we have two requirements, you will get the tutor and the other client will have to wait</li> */}
                                  </ol>
                                </div>
                                <div className='pending--payment--TC'>★  This amount is fully refundable in case we are not able to Provide you the tutor as per your need  ★</div>
                          </div>


                        </div>}
        {(showDemo)&&<div><DemoTutors/></div>}
        {(showAssigned)&&<div  className='assigned-tutors--container'><TutorCard data={{"status":"assigned"}}/></div>}
        {(showRequest)&&<div className='showRequest--container'>
                          <div className='showRequest--title-wrapper'>
                              <div className='showRequest--title'>Request Details</div>
                              {(!data.advance_payment&&!editRequest)&&<div className='showRequest--Edit-btn' onClick={()=>{
                                reSetAll()
                                setEditRequest(()=>true)
                                setShowRequest(()=>true)
                              }}> Edit <img src="/pencil-icon.svg" alt="pencil icon" width="15" height="15" />
                              </div>}
                              
                            </div>

                            <form onSubmit={(e)=>{
                              e.preventDefault()
                              reSetAll();
                              setShowRequest(()=>true);
                              postNewDeal(Form)
                            }} className='showRequest-inputs-wrapper'>

                                <div className='small-inputs--wrapper'>
                                  { 
                                      Form.map((input)=>{
                                          let formInputField;
                                          switch(input.inputType){
                                            case "dropDown-select":

                                                  let coursesList= input.options
                                                  if(input.inputName==="course_name"&&Form[0].inputName=="course_level"){
                                                    if(["Primary / Pre-Primary","LKG / UKG"].includes(Form[0].value))coursesList=["All Subjects"]
                                                    else if([ "1st Class", "2nd Class", "3rd Class", "4th Class", "5th Class", "6th Class", "7th Class"].includes(Form[0].value)){
                                                      coursesList=coursesList.filter((course)=>!['All Subjects','All Subjects Except Language',"Physics", "Chemistry", "Biology", "History", "Geography", "Political Science" ].includes(course))
                                                      coursesList=['All Subjects','All Subjects Except Language',...coursesList]
                                                    }
                                                    else if([ "8th Class", "9th Class", "10th Class"].includes(Form[0].value))coursesList=coursesList.filter((course)=>!['All Subjects','All Subjects Except Language'].includes(course))
                                                    else if(["11th Class ",  "12th Class"].includes(Form[0].value))coursesList=coursesList.filter((course)=>!['Science','Accountancy'].includes(course))                                                    
                                                  }
                                                  formInputField=<div className='small-input--wrapper'>
                                                                              <label>{input.title}</label>
                                                                              <div className='input-icon'>
                                                                              <div className='blue-dot-inside-input' ></div>
                                                                              <Select
                                                                                required
                                                                                options={coursesList.map((otp)=>({label:otp,isDisabled:!editRequest,value:otp.split(' ').join('_')}))}
                                                                                isMulti
                                                                                onChange={(val)=>{
                                                                                  if(!editRequest)return;
                                                                                  let allCourses=''
                                                                                  val.map((course)=>allCourses+=course.label+',')
                                                                                  setForm((pre)=>pre.map((itm)=>{
                                                                                                  if(itm.inputName==="course_name")itm.value=allCourses.substring(0,allCourses.length-1)
                                                                                                  return itm
                                                                                          }))
                                                                                }}
                                                                                value={input.value.split(',').map((opt)=>(opt.length>=1)&&({label:opt,value:opt.split(' ').join('_')}))}
                                                                              />
                                                                              </div>
                                                                          </div>
                                                                          break;
                                              case "dropDown":formInputField=<div className='small-input--wrapper'>
                                                                              <label>{input.title}</label>
                                                                              <div className='input-icon'>
                                                                              <div className='blue-dot-inside-input' ></div>
                                                                                  <select name={input.inputName} disabled={!editRequest} required  value={input.value} onChange={(e)=>{
                                                                                        // if(e.target.value==="")alert("Invalid Selection")
                                                                                        setForm((pre)=>
                                                                                              pre.map((itm)=>{
                                                                                                  if(itm.inputName===e.target.name)itm.value=e.target.value
                                                                                                  return itm
                                                                                              })
                                                                                          )
                                                                                        if(e.target.name==="stream_board"){
                                                                                          let _courseList=streamsData.find((dataField)=>(dataField.stream_name===e.target.value))
                                                                                          setForm((pre)=>
                                                                                              pre.map((itm)=>{
                                                                                                  return (itm.inputName==="course_name")? ({
                                                                                                      ...itm,"options":_courseList.courses,value:'',
                                                                                                  })
                                                                                                  :itm;
                                                                                              })
                                                                                          )
                                                                                        }
                                                                                        if(e.target.name==="course_level"&&[ "Primary / Pre-Primary", "LKG / UKG", "1st Class", "2nd Class", "3rd Class", "4th Class", "5th Class", "6th Class", "7th Class", "8th Class", "9th Class", "10th Class","11th Class",  "12th Class"].includes(e.target.value)){
                                                                                          if(["11th Class",  "12th Class"].includes(e.target.value)&&data.category_id!==4)fetchCourses({status:'newRequest',"category_id":4},e.target.value)
                                                                                          if([ "Primary / Pre-Primary", "LKG / UKG", "1st Class", "2nd Class", "3rd Class", "4th Class", "5th Class", "6th Class", "7th Class", "8th Class", "9th Class", "10th Class"].includes(e.target.value)&&data.category_id!==1)fetchCourses({status:'newRequest',"category_id":1},e.target.value)
                                                                                        }
                                                                                  }}>
                                                                                      <option value="" hidden> Select Value </option>{input.options.map((opt)=><option value={opt}> {opt} </option>)}
                                                                                  </select>
                                                                              </div>
                                                                          </div>
                                                                          break;
                                                default: formInputField=<div className='small-input--wrapper'>
                                                                          <label>{input.title}</label>
                                                                          <div className='input-icon'>
                                                                              <div className='blue-dot-inside-input' ></div>
                                                                              <input type='text' name={input.inputName} readOnly={!editRequest} onChange={(e)=>setForm((pre)=>
                                                                                      pre.map((itm)=>{
                                                                                          if(itm.inputName===e.target.name)itm.value=e.target.value
                                                                                          return itm
                                                                                      })
                                                                                  )} value={input.value} />
                                                                          </div>
                                                                      </div>
                                          }
                                          return formInputField;
                                              
                                      })
                                  }


                                </div>


                                {(editRequest)&&<div className='showRequest-Save-wrapper'>
                                  <div className='showRequest-cancel-wrapper'>
                                    <button className='secondary-btn' onClick={()=>{
                                      if(data.status==="newRequest")navigate('/dashboard')
                                      reSetAll();
                                      setShowRequest(()=>true);
                                      getDealFullDetails(data.id,Form,streamsData)
                                    }} type='button'>Cancel</button>
                                  </div>
                                  <button className='primary-btn' type='submit'>Save</button>
                                </div>}
                            </form>

                          </div>}

      </section>

      
      <section className='dealFlow-footer-btn--wrapper'>
            {(!showRequest)?<span onClick={()=>_Previous()}><SecondaryButton>Previous</SecondaryButton></span>:<></>}
            {(!showAssigned)?<span onClick={()=>_Next()}><Button>Next</Button></span>:<></>}
      </section>




    </main>
    :
    <main className='main' style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80vh"}}>    <div className='loading'>  <img src="./book-loader.gif" alt=" Loading . . .  " /></div></main>
  )
}
