export const EVENTS = {
  WHATSAPP_REGISTER: {
    event_name: "WHATSAPP_REGISTER",
    category: "HS-Student",
    action: "HS-Register-Whatsapp",
    label: "HS-Refister-Whatsapp",
    value: 1,
  },
  SMS_REGISTER: {
    event_name: "SMS_REGISTER",
    category: "HS-Student",
    action: "HS-Register-SMS",
    label: "HS-Refister-SMS",
    value: 1,
  },
  GIVEN_MOBILE_NO: {
    event_name: "GIVEN_MOBILE_NO",
    category: "HS-Student",
    action: "HS-Given Mobile No",
    label: "HS-Given Mobile No",
    value: 1,
  },
  GIVEN_PINCODE: {
    event_name: "GIVEN_PINCODE",
    category: "HS-Student",
    action: "HS-Given Pincode",
    label: "HS-Given Pincode",
    value: 1,
  },
  COUNSELLING_CLICKED: {
    event_name: "COUNCING_CLICKED",
    category: "HS-Student",
    action: "HS-Counselling-Clicked",
    label: "HS-Counselling-Clicked",
    value: 1,
  },
  CARD_CLICKED: {
    event_name: "CARD_CLICKED",
    category: "HS-Student",
    action: "HS-Card-Clicked",
    label: "HS-Card-Clicked",
    value: 1,
  },
  CURRICULAR_CLICKED: {
    event_name: "CURRICULAR_CLICKED",
    category: "HS-Student",
    action: "HS-Curricular-Clicked",
    label: "HS-Curricular-Clicked",
    value: 1,
  },
  HS_STEP_NO_1: {
    event_name: "HS_STEP_NO_1",
    category: "HS-Student",
    action: "HS-Step-NO-1",
    label: "HS-Step-NO-1",
    value: 1,
  },
  HS_STEP_NO_2: {
    event_name: "HS_STEP_NO_2",
    category: "HS-Student",
    action: "HS-Step-NO-2",
    label: "HS-Step-NO-2",
    value: 1,
  },
  HS_STEP_NO_3: {
    event_name: "HS_STEP_NO_3",
    category: "HS-Student",
    action: "HS-Step-NO-3",
    label: "HS-Step-NO-3",
    value: 1,
  },
  HS_STEP_NO_4: {
    event_name: "HS_STEP_NO_4",
    category: "HS-Student",
    action: "HS-Step-NO-4",
    label: "HS-Step-NO-4",
    value: 1,
  },
  FAQ_CLICKED: {
    event_name: "FAQ_CLICKED",
    category: "HS-Student",
    action: "HS-FAQ-Clicked",
    label: "HS-FAQ-Clicked",
    value: 1,
  },
  WHATSAPP_LOGIN: {
    event_name: "WHATSAPP_LOGIN",
    category: "HS-Student",
    action: "HS-Login-Whatsapp",
    label: "HS-Login-Whatsapp",
    value: 1,
  },
  SMS_LOGIN: {
    event_name: "SMS_LOGIN",
    category: "HS-Student",
    action: "HS-Login-SMS",
    label: "HS-Login-SMS",
    value: 1,
  },
  SUPPORT_CLICKED: {
    event_name: "SUPPORT_CLICKED",
    category: "HS-Student",
    action: "HS-Support-Clicked",
    label: "HS-Support-Clicked",
    value: 1,
  },
  DOC_UPLOAD: {
    event_name: "DOC_UPLOAD",
    category: "HS-Student",
    action: "HS-Doc-Upload",
    label: "HS-Doc-Upload",
    value: 1,
  },
  PAY_NOW_BUTTON: {
    event_name: "PAY_NOW_BUTTON",
    category: "HS-Student",
    action: "HS-Pay Now Button",
    label: "HS-Pay Now Button",
    value: 1,
  },

  MY_ADDRESS: {
    event_name: "MY_ADDRESS",
    category: "HS-Student",
    action: "HS-My-Address",
    label: "HS-My-Address",
    value: 1,
  },
  UPDATED_ADDRESS: {
    event_name: "UPDATED_ADDRESS",
    category: "HS-Student",
    action: "HS-Updated Address",
    label: "HS-Updated Address",
    value: 1,
  },
  PROFILE_PIC_UPLOAD: {
    event_name: "PROFILE_PIC_UPLOAD",
    category: "HS-Student",
    action: "HS-Profile Pic Upload",
    label: "HS-Profile Pic Upload",
    value: 1,
  },
  ACADEMY_CLICKED: {
    event_name: "ACADEMY_CLICKED",
    category: "HS-Student",
    action: "HS-Academy-Clicked",
    label: "HS-Academy-Clicked",
    value: 1,
  },
  NEW_REQUESTED_CLICKED: {
    event_name: "NEW_REQUESTED_CLICKED",
    category: "HS-Student",
    action: "HS-New Requested Clicked",
    label: "HS-New Requested Clicked",
    value: 1,
  },
};
