import React from 'react'

export default function Payments() {
  return (
    <main className='main'>

      <section className='pathway-requestBtn--wrapper'>
        <div className='pathway--wrapper'>
          <div>
                    <img
                    src="/homeicon.svg"
                    alt="homeicon icon"
                    width="18"
                    height="17"
                    />
          </div>
          <div>
                  <img
                    src="/rightArrow.svg"
                    alt="rightArrow icon"
                    width="11"
                    height="11"
                    />
          </div>
          <div className='pathway--btn'>Payments</div>
        </div>
        {/* <div className='request-btn--wrapper'>
              
        </div> */}
      </section>

      <section>
        <p className='payments--title'>Payments</p>

        <div className='payment-cards--wrapper'>
          <div className='payment-cards--left--wrapper'>
            <div className='payment-card-payment-name--wrapper'>
              <div className='payment-card-payment-name--left-border'></div>
              <div className='payment-card-payment-name'>Payment Name</div>
            </div>
            <div className='payment-card-description'>Pay Title : Lorem Ipsum is simply</div>
            <div className='payment-card-due-date'>
              <img
                    src="/clock.svg"
                    alt="clock icon"
                    width="11"
                    height="11"
                    />
              Due Date : 01/02/2023
              </div>
          </div>
          <div className='payment-cards--right--wrapper'>
            <div className='payment-amount'>Rs. 5000/-</div>
            <div className='payment-paynow-btn'>Pay Now</div>
          </div>
        </div>

        <div className='payment-cards--wrapper'>
          <div className='payment-cards--left--wrapper'>
            <div className='payment-card-payment-name--wrapper'>
              <div className='payment-card-payment-name--left-border'></div>
              <div className='payment-card-payment-name'>Payment Name</div>
            </div>
            <div className='payment-card-description'>Pay Title : Lorem Ipsum is simply</div>
            <div className='payment-card-due-date'>
              <img
                    src="/clock.svg"
                    alt="clock icon"
                    width="11"
                    height="11"
                    />
              Due Date : 01/02/2023
              </div>
          </div>
          <div className='payment-cards--right--wrapper'>
            <div className='payment-amount'>Rs. 5000/-</div>
            <div className='payment-paynow-btn'>Pay Now</div>
          </div>
        </div>
        
        <div className='payment-cards--wrapper'>
          <div className='payment-cards--left--wrapper'>
            <div className='payment-card-payment-name--wrapper'>
              <div className='payment-card-payment-name--left-border'></div>
              <div className='payment-card-payment-name'>Payment Name</div>
            </div>
            <div className='payment-card-description'>Pay Title : Lorem Ipsum is simply</div>
            <div className='payment-card-due-date'>
              <img
                    src="/clock.svg"
                    alt="clock icon"
                    width="11"
                    height="11"
                    />
              Due Date : 01/02/2023
              </div>
          </div>
          <div className='payment-cards--right--wrapper'>
            <div className='payment-amount'>Rs. 5000/-</div>
            <div className='payment-paynow-btn'>Pay Now</div>
          </div>
        </div>
        
        <div className='payment-cards--wrapper'>
          <div className='payment-cards--left--wrapper'>
            <div className='payment-card-payment-name--wrapper'>
              <div className='payment-card-payment-name--left-border'></div>
              <div className='payment-card-payment-name'>Payment Name</div>
            </div>
            <div className='payment-card-description'>Pay Title : Lorem Ipsum is simply</div>
            <div className='payment-card-due-date'>
              <img
                    src="/clock.svg"
                    alt="clock icon"
                    width="11"
                    height="11"
                    />
              Due Date : 01/02/2023
              </div>
          </div>
          <div className='payment-cards--right--wrapper'>
            <div className='payment-amount'>Rs. 5000/-</div>
            <div className='payment-paynow-btn'>Pay Now</div>
          </div>
        </div>

        <div className='payment-cards--wrapper'>
          <div className='payment-cards--left--wrapper'>
            <div className='payment-card-payment-name--wrapper'>
              <div className='payment-card-payment-name--left-border'></div>
              <div className='payment-card-payment-name'>Payment Name</div>
            </div>
            <div className='payment-card-description'>Pay Title : Lorem Ipsum is simply</div>
            <div className='payment-card-due-date'>
              <img
                    src="/clock.svg"
                    alt="clock icon"
                    width="11"
                    height="11"
                    />
              Due Date : 01/02/2023
              </div>
          </div>
          <div className='payment-cards--right--wrapper'>
            <div className='payment-amount'>Rs. 5000/-</div>
            <div className='payment-paynow-btn'>Pay Now</div>
          </div>
        </div>

        <div className='payment-cards--wrapper'>
          <div className='payment-cards--left--wrapper'>
            <div className='payment-card-payment-name--wrapper'>
              <div className='payment-card-payment-name--left-border'></div>
              <div className='payment-card-payment-name'>Payment Name</div>
            </div>
            <div className='payment-card-description'>Pay Title : Lorem Ipsum is simply</div>
            <div className='payment-card-due-date'>
              <img
                    src="/clock.svg"
                    alt="clock icon"
                    width="11"
                    height="11"
                    />
              Due Date : 01/02/2023
              </div>
          </div>
          <div className='payment-cards--right--wrapper'>
            <div className='payment-amount'>Rs. 5000/-</div>
            <div className='payment-paynow-btn'>Pay Now</div>
          </div>
        </div>


      </section>



    </main>
  )
}
