import React from 'react';
function Assignment() {



    return (
        <div style={{margin:"100px"}}>Assignment
        </div>
    );
}

export default Assignment



