import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from "../components/button";
import DealRequestCard from "../components/deal-request-card";
import axios from "../axios";
import { logEvent } from "../analytics";
import { EVENTS } from "../constants/ga-constants";
const currentDate = new Date();
const currentHour = currentDate.getHours();

export default function Dashboard({ setshowNavBar }) {
  const navigate = useNavigate();
  const [deals, setDeals] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentPopUp, setPaymentPopUp] = useState(0);

  useEffect(() => {
    let jwt = JSON.parse(localStorage.getItem("homeshiksha-jwt"));
    if (!jwt) {
      navigate("/");
      window.location.reload();
    } else {
      setshowNavBar(true);
      getUserDashboardDetails();
      localStorage.setItem("dealData", JSON.stringify({}));
      // if(JSON.parse(localStorage.getItem("paymentPopUp")))setPaymentPopUp(true)
      setIsMobile(() => window.innerWidth <= 768);
    }
  }, []);

  const getUserDashboardDetails = () => {
    try {
      axios
        .get(`v1/student/dashboard`)
        .then((data) => {
          const response = data.data;
          const userData = {
            profilePic: response.profile_pic,
            Name: response.user_name,
            userId: response.id,
          };

          localStorage.setItem("userDetails", JSON.stringify(userData));
          setUserDetails(userData);
          setDeals(response.requests_data);
          if (
            JSON.parse(localStorage.getItem("paymentPopUp")) &&
            !response.requests_data?.find((deal) => deal.advance_payment > 0)
          )
            setPaymentPopUp(response.requests_data[0]?.advance_amount);
          setLoading(false);
        })
        .catch((err) => {});
    } catch (error) {}
  };

  const timeOfDay = () => {
    let timeOfDay;

    if (currentHour >= 5 && currentHour < 12) {
      timeOfDay = "Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      timeOfDay = "Afternoon";
    } else {
      timeOfDay = "Evening";
    }
    return timeOfDay;
  };

  const hidepopUP = () => {
    localStorage.setItem("paymentPopUp", JSON.stringify(false));
    setPaymentPopUp(0);
  };

  const payNow = () => {
    axios
      .post("v1/subscribe-to-plan")
      .then((response) => {
        const requestData = response.data;
        if (requestData.data.includes("https:")) {
          setTimeout(() => {
            window.open(requestData.data, "_blank");
          });
        }
      })
      .catch((err) => {});
  };

  return !loading ? (
    <main className="main">
      {paymentPopUp !== 0 && (
        <div className="dashboard-payment-popup">
          <div
            className="tutor-popup-wrapper--transparent-screen"
            style={{ zIndex: 80 }}
            onClick={() => {
              hidepopUP();
            }}
          ></div>
          <div
            className="tutor-popup-wrapper--content-screen"
            style={{ zIndex: 81 }}
          >
            <div
              style={{ textAlign: "end", margin: "3px 8px", cursor: "pointer" }}
              onClick={() => {
                hidepopUP();
              }}
            >
              Close
            </div>
            <div className="payment-pending--wrapper">
              <div className="payment-pending--title">
                Upgrade to HOMESHIKSHA PREFERRED CUSTOMER
              </div>
              <div className="payment-pending--description">
                *We get over 500 requirements for tutors daily. Sometimes its
                not possible for us to arrange tutors for everyone. If you
                genuinely have a requirement for a tutor, then pay this small
                'refundable' fee of Rs.{paymentPopUp} and your requirement will
                be given first priority.
              </div>
              <div
                className="payment-pending--paynow-btn"
                onClick={() => {
                  payNow();
                  logEvent(EVENTS.PAY_NOW_BUTTON.event_name);
                }}
              >
                <Button>Pay Now</Button>
              </div>
              <div className="payment-pending--payment-amount">
                Advance Payment {paymentPopUp}/-
              </div>
            </div>
            <div className="pending--process-wrapper">
              <div className="pending--process--title payment-pending--description">
                Process :{" "}
              </div>
              <div className="pending--process--steps--wrapper">
                <ol>
                  <li>
                  Dedicated Customer Relationship manager will be assigned to handle your requirement.
                  </li>
                  <li>
                  You will become our subscribed customer for 6 months. You can avail free tutor replacements and have the option to request an unlimited number of tutors during this period.
                  </li>
                </ol>
              </div>
              <div className="pending--payment--TC">
                ★ This amount is fully refundable in case we are not able to
                Provide you the tutor as per your need ★
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="greetings--wrapper">
        <div className="greetings--wrapper--info-wrapper">
          {!isMobile ? (
            <div className="profilePic--wrapper profilePic--wrapper-2">
              {" "}
              <img
                src={
                  userDetails.profilePic != undefined &&
                  userDetails.profilePic != "null"
                    ? userDetails.profilePic
                    : "./person-icon.png"
                }
                alt="profilePic"
                height="100px"
              />
            </div>
          ) : (
            <div className="profilePic--wrapper">
              {" "}
              <img
                src={
                  userDetails.profilePic != undefined &&
                  userDetails.profilePic != "null"
                    ? userDetails.profilePic
                    : "./person-icon.png"
                }
                alt="user profile pic"
                height="50"
              />
            </div>
          )}

          <div className="greetings--info">
            <div className="greetings--user">
              Good {timeOfDay()} {userDetails.Name.split(" ")[0]} !
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="user--overview">Here's overview </div>
              <div className="greetings--wrapper--new-request-btn-mobile">
                <NavLink to="/new-request">
                  <Button>New Request</Button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="greetings--wrapper--new-request-btn-desktop">
          <NavLink to="/new-request">
            <Button>New Request</Button>
          </NavLink>
        </div>
      </section>

      {isMobile && (
        <section className="need-tutor-add-wrapper">
          {" "}
          <img
            src="/need-tutor-add.svg"
            alt="need tutor pic"
            width="100%"
            height="97"
          />
        </section>
      )}

      <section className="request-deals--wrapper">
        <section>
          <div>
            <div className="request-deals--wrapper--left--title">
              Current request
            </div>
            <div
              className="request-deals--wrapper--left"
              onClick={() => {
                logEvent(EVENTS.CARD_CLICKED.event_name);
              }}
            >
              {deals
                ?.slice()
                .reverse()
                .map((i, indx) => (
                  <DealRequestCard key={indx} data={i} />
                ))}
            </div>
          </div>
          <div className="ads-space--wrapper">
            What to do need help with?
            <div className="formtype-select-options-wrapper">
              <NavLink to="/new-academy-request">
                <div className="formtype-select-option-2" onClick={() => {}}>
                  <img src="/academy-icon.svg" alt="academy icon" />
                  <span
                    style={{ color: "rgba(0, 141, 240, 1)", margin: "0 5px" }}
                  >
                    Academy
                  </span>
                </div>
              </NavLink>

              <div
                className="formtype-select-option-1"
                onClick={() => {
                  localStorage.setItem(
                    "dealData",
                    JSON.stringify({
                      advance_payment: 0,
                      base_module_student_id: "",
                      category_id: 2,
                      color: "#FF0000",
                      created_at: "",
                      id: "",
                      status: "newRequest",
                      student_requirments: "",
                      tution_type: "",
                    })
                  );
                  navigate("/deal-details");
                  logEvent(EVENTS.CARD_CLICKED.event_name);
                }}
              >
                <img src="/curricular-icon.svg" alt="Curricular icon" />
                <span style={{ color: "rgba(53, 53, 53, 1)", margin: "0 5px" }}>
                  Curricular
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="request-deals--wrapper--right">
          <div>
            <div className="request-deals--wrapper--right--title">Activity</div>
            <div className="preWatch-video--wrapper">
              <img
                src="https://homeshiksha.blr1.cdn.digitaloceanspaces.com/uat/users/profile-pictures/1F3jkxwuH1rM-pexels-katerina-holmes.png"
                alt="pre watch img "
                width="90%"
                height="90%"
              />
              <div className="preWatch-video--playicon-wrapper">
                <img
                  src="/PolygonPlayicon.svg"
                  alt="PolygonPlayicon"
                  width="20px"
                  height="20px"
                />
              </div>
            </div>
          </div>

          <div className="reviews">
            <div>
              <div>
                <img src="./google-icon.svg" alt="profilePic" />
                <span
                  style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
                >
                  {" "}
                  Google
                </span>
              </div>
              <div>
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                Trusted Source{" "}
                <span style={{ color: "black", fontWeight: "500" }}>4.8</span>{" "}
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                <span style={{ color: "black", fontWeight: "500" }}>
                  1,013+{" "}
                </span>
                reviews
              </div>
            </div>

            <div>
              <div>
                <img src="./jd-icon.svg" alt="profilePic" />
                <span
                  style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
                >
                  {" "}
                  JustDail
                </span>
              </div>
              <div>
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                Trusted Source{" "}
                <span style={{ color: "black", fontWeight: "500" }}>4.7</span>{" "}
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                <span style={{ color: "black", fontWeight: "500" }}>827+ </span>
                reviews
              </div>
            </div>

            <div>
              <div>
                <img src="./mouth-shut-icon.gif" alt="profilePic" />
                <span
                  style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
                >
                  {" "}
                </span>
              </div>
              <div>
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                Trusted Source{" "}
                <span style={{ color: "black", fontWeight: "500" }}>4.5</span>{" "}
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                <span style={{ color: "black", fontWeight: "500" }}>
                  1177+{" "}
                </span>
                reviews
              </div>
            </div>

            <div>
              <div>
                <img src="./glassdoor-icon.svg" alt="profilePic" />
                <span
                  style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
                >
                  {" "}
                  Glassdoor
                </span>
              </div>
              <div>
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
                <img
                  src="./star-icon.svg"
                  alt="star icon"
                  className="star-icon"
                />
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                Trusted Source{" "}
                <span style={{ color: "black", fontWeight: "500" }}>4.8</span>{" "}
              </div>
              <div
                style={{ color: "rgba(130, 130, 130, 1)" }}
                className="review-content"
              >
                <span style={{ color: "black", fontWeight: "500" }}>50+</span>
                reviews
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  ) : (
    <main className="main">
      <div
        className="loading"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <img src="./book-loader.gif" alt=" Loading . . .  " />
      </div>
    </main>
  );
}
