import React, { useEffect, useState } from "react";
import "./demo-card.css";
import axios from "../axios";

export default function TutorCard(props) {
  const [tutorData, setTutorData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  let _dealData = JSON.parse(localStorage.getItem("dealData"));

  useEffect(() => {
    let dealId = _dealData.id;
    axios
      .get(`v1/student/tutors-for-deals`, {
        params: {
          dealId: !["hired", "fired"].includes(props.data.status.toLowerCase())
            ? dealId
            : null,
        },
      })
      .then((response) => {
        if (response.data.length !== 0) {
          setTutorData(() =>
            response.data.tutorsData.reduce((pre, data) => {
              return [...pre, ...data.tutors];
            }, [])
          );
        } else {
          setTutorData(() => []);
        }
      })
      .catch((err) => {
        setTutorData(() => []);
      });
    setIsMobile(() => window.innerWidth <= 768);
  }, []);

  const hireTutor = () => {
    props.onClick(true);
  };
  const fireTutor = () => {
    props.onClick(true);
  };

  return tutorData.length !== 0 ? (
    tutorData.map((data) => {
      return (
        props.data.status.toLowerCase() === "hired"
          ? ["hired", "assigned"].includes(data.status)
          : props.data.status.toLowerCase() === data.status
      ) ? (
        <div className="demo-card--container">
          <div
            className="demo-card--container-top-bar-wrapper"
            style={{ justifyContent: "start" }}
          >
            <div className="tutor-profilePic--wrapper">
              {!isMobile && (
                <img
                  src={`${
                    data.profile_pic
                      ? data.profile_pic
                      : "./" + data.gender + "-icon.svg"
                  }`}
                  alt={"./" + data.gender + "-icon.svg"}
                  // width="100"
                  height={`${data.profile_pic ? "140" : "100"}`}
                />
              )}
              {isMobile && (
                <div>
                  <img
                    src={`${
                      data.profile_pic
                        ? data.profile_pic
                        : "./" + data.gender + "-icon.svg"
                    }`}
                    alt={"./" + data.gender + "-icon.svg"}
                    // width="60"
                    height={`${data.profile_pic ? "90" : "50"}`}
                  />
                </div>
              )}
            </div>

            {isMobile && (
              <div>
                <div className="demo-card--container--info-wrapper--left--tutor-name">
                  {data.first_name}
                </div>
                {/* <div><span style={{color:"rgba(130, 130, 130, 1)",fontSize:"14px",fontWeight:"600"}}>Gender :</span> {data.gender}</div> */}
              </div>
            )}
          </div>

          <div className="demo-card--container--info-wrapper">
            <div className="demo-card--container--info-wrapper--left">
              {!isMobile && (
                <div>
                  <div className="demo-card--container--info-wrapper--left--tutor-name">
                    {data.first_name}
                  </div>
                </div>
              )}
              <div>
                <span
                  style={{
                    color: "rgba(130, 130, 130, 1)",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Gender :
                </span>{" "}
                {data.gender}
              </div>
              <div>
                <span
                  style={{
                    color: "rgba(130, 130, 130, 1)",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Age :
                </span>{" "}
                {data.age}
              </div>
              <div>
                <span
                  style={{
                    color: "rgba(130, 130, 130, 1)",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  phone :
                </span>{" "}
                {data.phone}
              </div>
              {/* <div><span style={{color:"rgba(130, 130, 130, 1)",fontSize:"14px",fontWeight:"600"}}>Expertise :</span> {data.course_names}</div> */}
              <div>
                <span
                  style={{
                    color: "rgba(130, 130, 130, 1)",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Experience :
                </span>{" "}
                {data.experience}
              </div>
            </div>
            <div className="demo-card--container--info-wrapper--right">
              <div className="demo-card--container--info--view-wrapper">
                View
              </div>
              {data.status !== "hired" && data.status !== "fired" ? (
                <div className="demo-card--container--info--rating tutor-card-complete-payment">
                  Complete Payment
                </div>
              ) : (
                <></>
              )}
              <div className="demo-card--container--info--ratings--wrapper end-content-desktop">
                {/* <div className='demo-card--container--info--rating'> Ratings {data.rating}</div> */}
                {data.status !== "hired" && data.status !== "fired" ? (
                  <div className="demo-card--container--info--invite-for-demo--wrapper">
                    Start Classes
                  </div>
                ) : data.status === "hired" ? (
                  <div
                    className="demo-card--container--info--invite-for-demo--wrapper hireTutor-btn"
                    onClick={() => {
                      hireTutor();
                    }}
                  >
                    Start Classes
                  </div>
                ) : data.status === "fired" ? (
                  <div
                    className="demo-card--container--info--invite-for-demo--wrapper fireTutor-btn"
                    onClick={() => fireTutor()}
                  >
                    Stop
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      );
    })
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {" "}
      Currently there is no tutors assigned to you
    </div>
  );
}
