import React, { useEffect, useState, useCallback } from "react";
import "./index.css";
import Autocomplete from "react-google-autocomplete";
import axios from "../axios";
import Faq from "react-faq-component";
import ReactCardSlider from "react-card-slider-component";
import { useNavigate } from "react-router-dom";
import {
  genderConversion,
  modeOfTutionconversion,
  citysList
} from "../constants/form-templates";
import { logEvent } from "../analytics";
import { EVENTS } from "../constants/ga-constants";
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const data = {
  // title: "FAQ (How it works)",

  rows: [
    {
      title: "Do you provide home tutors at my door step? ",
      content: `Yes, HomeShiksha provide both “Home Tutors at your doorstep” and “Online Tutors” as per 
          your need`,
    },
    {
      title: " Do you also provide tutor for extracurricular activities? ",
      content: `Yes, HomeShiksha provide tutors for dancing, singing, Painting, cooking and many other co-
          curricular activities. `,
    },
    {
      title:
        " Do you provide free demo class before we finalise with any tutor? ",
      content: `Yes, we provide free demo class. You can have demo sessions with our tutors to select the 
          best one as per your need.`,
    },
    {
      title: "Do you provide female tutors for female candidates?  ",
      content: `Yes, we provide female tutors for female students.`,
    },
    {
      title: "How many tutors can we try for demo classes? ",
      content: `Normally, the first tutor provided by our team would be the best one. However, if you and 
          your kid are not comfortable with the tutor then we would go ahead with a 2nd option. `,
    },
    {
      title:
        "Can we replace a tutor after a month or two in case if we don't like the tutor? ",
      content: `Yes, obviously you can change tutor at any time, we just request parents to inform us 15days 
          prior to find the replacement. `,
    },
    {
      title: "How many replacement of tutors can be Provided by HomeShiksha?",
      content: `We don't have any limitations in your tutor replacement. Whenever you want tutor needs to 
          be replaced, then Our team will help you with replacement.`,
    },
    {
      title:
        "How soon HomeShiksha provides the Tutor once we give our tuition requirement?  ",
      content: `Our team works manually to find the best tutor as per your requirement.  
          If you need “Online tutor” then within 1-5 hours you get a tutor. 
          If you are looking for “Home Tutor at your door step” then generally it take 1-7 working 
          days to get the best tutor.`,
    },
  ],
};

const styles = {
  bgColor: "white",
  titleTextColor: "Black",
  rowTitleColor: "Black",
  rowContentColor: "grey",
  arrowColor: "black",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  tabFocus: true,
  expandIcon: "+",
  collapseIcon: "-",
};

const customerReviews = [
  {
    reviewTitle: " Very Organised",
    location: "Bangalore,India",
    name: "Geetha Sridhara",
    content:
      "	I got to know about HomeShiksha from a parent and I thought of giving a try for my kid's tuition. It's very organised and they gave 3 teachers for demo and I chose the best amongst the three. The team is approachable and provide updates on time. We get good quality learning experience at our home as per our convenient time.",
  },
  {
    reviewTitle: " Very Good Personal Tutor",
    location: "Mumbai,India",
    name: "KanchyKanz",
    content:
      "	Really very happy with this institution as I could get a very good personal tutor for my daughter. Prashanthini has been very good and we could see a difference within a month in our daughter's learning curve. All thanks to Prashanthini who treats our daughter as her own.",
  },
  {
    reviewTitle: " Friendly Staff",
    location: "Hyderabad,India",
    name: "Sowjanya Balireddy",
    content:
      "	Very good institute and friendly staff. We recently hired a shadow teacher from Home shiksha and she has very good knowledge and idea about what she need to work on based on the child needs and requirements. Based on that she stated working on it… which is really good. Thanks home shiksha.. 👍",
  },
  {
    reviewTitle: " Professional Services",
    location: "Bangalore,India",
    name: "Dassan Gita",
    content:
      "	I had contacted Homesiksha for shadow teacher requirement for my child, 6 years old. Ms Moumita was my contact point at Homesiksha Bangalore branch ,She was very professional and attentive to my requirements .I found Homesiksha services very professional, prompt and fuss free. Have recommended their services to my friends aswell.",
  },
  {
    reviewTitle: " Professional Approach",
    location: "Hyderabad,India",
    name: "Aniruddha Banik",
    content:
      " I got instant response upon my queries. They had provided very experienced and good tutor for my 12 years old son who is in CBSE board. Happy yo share that they are excellent in service commitment and have good professional approach towards the services provided. Thank you Home Shiksha for supporting parents like us who need good home tutor",
  },
  {
    reviewTitle: " Excellent Tuitions",
    location: "Bangalore,India",
    name: "Fathima",
    content:
      "	Homeshiksha has connected me to some excellent tuitions which take into consideration the requirements and convenience of both the students, as well as the tutors. 10/10",
  },
  {
    reviewTitle: " Best platform",
    location: "Hyderabad,India",
    name: "Sravanthivd",
    content:
      "	I looked for shadow teacher service..They give the options for us to choose the best for our requirement...Overall the service is good as of now.",
  },
  {
    reviewTitle: " Long Lasting Relationship",
    location: "Bangalore,India",
    name: "Rachel Poovaiah",
    content:
      "	Homeshiksha has been proactive in providing quality teachers in a short span of time.They have coordinated well to understand my needs. Looking forward to have a long lasting relationship with them.",
  },
  {
    reviewTitle: " Good Support",
    location: "Hyderabad,India",
    name: "Sindhuja",
    content:
      "	Home siksha has given me a very good support by searching a qualified teacher who Can match my requirements. They have shown enough patience. Thank you for that # it depends on the teacher though.",
  },
  {
    reviewTitle: " Response Quick",
    location: "Mumbai,India",
    name: "Sheena Mehta",
    content:
      "	After shifting to Mumbai and duped by so many private agencies I gave one last shot by contacting Home shiksha. Believe me they were a life saviour that time . Response time was quick, very professional, polite and humble. If I can add always there to lend an ear to hear your issues. I have finally got two tutors from them and will soon be getting one more for my daughter.. Thankyou Homeshiksha.",
  },
  {
    reviewTitle: " Being So Reliable",
    location: "Hyderabad,India",
    name: "Gautham Kapoor",
    content:
      "	HomeShiksha is a superb platform to learn. I am doing MBA Finance with no commerce/ economics background. So Home Shiksha has been my go to place for past one year to seek great tutors who are guiding me through subjects unfamiliar to me. Thanks for being so reliable",
  },
  {
    reviewTitle: " Great platform",
    location: "Mumbai,India",
    name: "Rahul Chavan",
    content:
      "	Home Shiksha provides great, and useful platform in educational field. I have been grateful to them for arranging tutors, and classes as per our kids requirements.",
  },
  {
    reviewTitle: " Very Responsible",
    location: "Hyderabad,India",
    name: "Radhika Tatiyal",
    content:
      "	I got in touch with Home Shiksha for a teacher and the response by Divya was always prompt. She helped us find a very reliable and responsible teacher as per the requirements. Divya is soft spoken and quickly caught hold of what has to be delivered. Glad about this association. Would highly recommend Home Shiksha to anybody looking for good tutors.",
  },
  {
    reviewTitle: " Very Good Support",
    location: "Hyderabad,India",
    name: "Rajakumara M. N Raaj",
    content:
      "	Very good support for clients and nice result for control behaviour related issues .... They are providing very gud and supportive thepists..... 👏👏👏👏😊😊 …",
  },
  {
    reviewTitle: " Skilled Shadow Teacher",
    location: "Bangalore,India",
    name: "Saroj Das",
    content:
      "	I was looking for shadow teacher and then I connect with Divya. She helped me to get very skilled shadow teacher and are also trained for special need children. I am very happy with the service so far and she also take care of my budget.",
  },
  {
    reviewTitle: " Full Satisfied",
    location: "Hyderabad,India",
    name: "Pooja Behera",
    content:
      "At first I was really scared that how would I explain my Son's condition as he has ASD to find good teacher for him ,but I am so glad I got connected with HomeShiksha as they helped me a lot , understood the need and accordingly find the best one . Divya was in constant touch unless I am full satisfied With kind of teacher I am looking for. Thank you HomeShiksha!!",
  },
  {
    reviewTitle: " Very Effective",
    location: "Mumbai,India",
    name: "Vaishali Tongay",
    content:
      "	This platform is very good and has been playing an very important and effective role in filling the gap of supply and demand of the tutors and students. The platform and the team itself is very friendly and humble. Great platform to find tutors.",
  },
  {
    reviewTitle: " Good Service",
    location: "Hyderabad,India",
    name: "Vanita Sanap",
    content:
      "	Too good service....understand parents need well and provide all the required support in good price....thanks a lot",
  },
  {
    reviewTitle: " Comfortable Platform",
    location: "Hyderabad,India",
    name: "Shaswe Sha",
    content:
      "	Glad to contact them .They understood my urgent requirement and were real quick to find the best shadow teacher. My kid is real happy comfortable and enjoys learning from her. Best and easy way to find a teacher for your kid.",
  },
  {
    reviewTitle: " Must Try Once",
    location: "Bangalore,India",
    name: "Arti Srivastava",
    content:
      "	Homeshiksha gives tutors the best opportunity and offers based on there qualifications 👍🏻. You guys should definitely try once. …",
  },
];

const slides = [
  {
    image: "https://homeshiksha.com/static/images/homeshiksha/nupur.jpg",
    title: "Nupur Mittal",
    description: "Maths Teacher ,  3+ year experience.",
    clickEvent: () => {},
  },
  {
    image: "https://homeshiksha.com/static/images/homeshiksha/Chandini1.jpg",
    title: "Chandini Chabra - Dance Teacher",
    description: "National level dancer, 8+ dance style.",
    clickEvent: () => {},
  },
  {
    image: "https://homeshiksha.com/static/images/homeshiksha/naina.jpg",
    title: "Naina Sinha - Music Teacher",
    description: "Experience in various styles of musics",
    clickEvent: () => {},
  },
  {
    image: "https://homeshiksha.com/static/images/homeshiksha/reema.jpg",
    title: "Reema Jain - Physics Teacher",
    description: "5 Years experience of teaching physics",
    clickEvent: () => {},
  },
  {
    image: "https://homeshiksha.com/static/images/homeshiksha/sita.jpg",
    title: "Gurpreet Kaur - Yoga & Aerobic",
    description: "Provide home tuitions for yoga and aerobic",
    clickEvent: () => {},
  },
  {
    image: "https://homeshiksha.com/static/images/homeshiksha/maya.jpg",
    title: "Rashmi - Cooking & Embroidery",
    description: "Well known cooking faculty in bangalore",
    clickEvent: () => {},
  },
  {
    image:
      "https://homeshiksha.com/static/images/homeshiksha/Rajitha%20Inter%20Faculty1.jpg",
    title: "Rajitha Nair - Inter teacher",
    description: "Best faculty for Intermediate - all subjects",
    clickEvent: () => {},
  },
  {
    image:
      "https://homeshiksha.com/static/images/homeshiksha/Syed%20Omer%20English%20Faculty1.jpg",
    title: "Syed Omer",
    description: "English Teacher,4+ year of experience",
    clickEvent: () => {},
  },
  {
    image:
      "https://homeshiksha.com/static/images/homeshiksha/Rai%20Pradeep%20Entrance%20Exams1.jpg",
    title: "Rai Pradeep - Entrance Exams",
    description: "Expert in teaching entrance level Exams topics",
    clickEvent: () => {},
  },
  {
    image:
      "https://homeshiksha.com/static/images/homeshiksha/shekher_maths.jpg",
    title: "Shekher Rawat - Maths Teacher",
    description: "Exellent knowledge of mathematics B-tech",
    clickEvent: () => {},
  },
  {
    image:
      "https://homeshiksha.com/static/images/homeshiksha/Dinesh%20Chandra%20Motivational%20Faculty1.jpg",
    title: "Dinesh Chandra-Motivational Faculty",
    description: "Highly experience in motivational speechess",
    clickEvent: () => {},
  },
  {
    image: "https://homeshiksha.com/static/images/homeshiksha/Hussain1.jpg",
    title: "Hussain - Computer Teacher",
    description: "Expert in multiple computer laguages",
    clickEvent: () => {},
  },
];
const categorys = {
  "school education": "1",
  Curricular: "2",
  "Competitive Exams": "3",
  "Intermediate Courses": "4",
  "Abroad Exams": "5",
  "Learn Language": "6",
  "IT Courses": "7",
  "Special Kids (ADHD /ADD /AUTISM)": "13",
  1: "school education",
  2: "Curricular",
  3: "Competitive Exams",
  4: "Intermediate Courses",
  5: "Abroad Exams",
  6: "Learn Language",
  7: "IT Courses",
  13: "Special Kids (ADHD /ADD /AUTISM)",
};
export default function NeedATutor() {
  const [currentStep, setCurrentStep] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [smsOtpVerification, setSmsOtpVerification] = useState(false);

  const [otp, setOtp] = useState();
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [verificationProcess, setVerificationProcess] = useState(false);
  const [showTC, setShowTC] = useState(false);

  const [Form, setForm] = useState([
    {
      viewed: true,
      filled: false,
      fields: [
        {
          type: "dropDown",
          open: true,
          name: "Choose Study Area",
          placeHolder: "Choose Study Area",
          options: [
            "school education",
            "Learn Language",
            "Curricular",
            "Competitive Exams",
            // 'Intermediate Courses',
            // 'Abroad Exams',
            "IT Courses",
            "Special Kids (ADHD /ADD /AUTISM)",
          ],
          value: "",
        },
      ],
    },
    {
      viewed: false,
      filled: false,
      fields: [
        {
          type: "dropDown",
          open: false,
          name: "Prefered Tutor Gender",
          placeHolder: "Require Male/Female Tutor ?",
          options: [
            "I need only Male Tutor",
            "I need only Female Tutor",
            "Either Male or Female ",
          ],
          value: "",
        },
        {
          type: "dropDown",
          open: false,
          name: "Mode of tuition",
          placeHolder: "Enter Your Mode of tuition",
          options: [
            "Looking for Online Tutor",
            "Looking for Home Tutor",
            "Either Home/Online Tutor",
          ],
          value: "",
        },
        {
          type: "dropDown",
          open: false,
          name: "How many days in a week you need tuition?",
          placeHolder: "Select days per week ",
          options: [
            "1 Day a week",
            "2 Days a week",
            "3 Days a week",
            "4 Days a week",
            "5 Days a week",
            "6 Days a week",
          ],
          value: "",
        },
        {
          type: "dropDown",
          open: false,
          name: "When you would like to start ?",
          placeHolder: "Select Starting day",
          options: [
            "Immediately",
            "In Few Days",
            "In A Week",
            "In 10 Days",
            "In This Month",
            "Next Month",
          ],
          value: "",
        },
        {
          type: "dropDown",
          open: false,
          name: "When State you are from ?",
          placeHolder: "Select State",
          options:citysList,
          value: "",
        },
        {
          type: "address",
          name: "Location",
          placeHolder: "Location /Pin code / Latitude and Longitude",
          value: "",
        },
      ],
    },
    {
      viewed: false,
      filled: false,
      fields: [
        {
          type: "text",
          name: "First Name",
          placeHolder: "Enter Your First Name",
          value: "",
        },
        {
          type: "text",
          name: "Last Name",
          placeHolder: "Enter Your Last Name",
          value: "",
        },
        {
          type: "dropDown",
          open: true,
          name: " Your Gender",
          placeHolder: "Select Your Gender",
          options: ["male", "female"],
          value: "",
        },
        {
          type: "tel",
          name: "Mobile",
          placeHolder: "Enter Your 10 Digit Mobile Number",
          value: "",
        },
        {
          type: "email",
          name: "Email",
          placeHolder: "Enter Your Email",
          value: "",
        },
        {
          type: "checkbox",
          name: "",
          placeHolder: "",
          value: false,
        },
      ],
    },
    {
      viewed: false,
      filled: false,
      fields: [
        {
          type: "verificationBtn",
          name: "OTP",
          placeHolder: "Enter Your OTP",
          value: "",
        },
      ],
    },
  ]);
  const [showOtpError, setShowOtpError] = useState("");
  const navigate = useNavigate();

  const registerAndGetOTP = (login_method = "sms") => {
    let category_id = categorys[Form[0].fields[0].value];
    let obj = {};
    if (category_id === "1" || category_id === "4") {
      obj = {
        level: Form[0].fields[1].value,
        stream_id: Form[0].fields[2].value,
        course: Form[0].fields[3].value,
        category_id: ["11th Class", "12th Class"].includes(
          Form[0].fields[1].value
        )
          ? "4"
          : "1",
      };
    } else {
      obj = {
        category_id,
        level: "Advanced Level",
        stream_id: Form[0].fields[1].value,
        course: Form[0].fields[2].value,
      };
    }
    obj = {
      ...obj,
      tutor_gender_preference: genderConversion[Form[1].fields[0].value],
      mode_of_tution: modeOfTutionconversion[Form[1].fields[1].value],
      days_per_week: Form[1].fields[2].value,
      when_to_start: Form[1].fields[3].value,

      first_name: Form[2].fields[0].value,
      last_name: Form[2].fields[1].value,
      gender: Form[2].fields[2].value,
      phone: Form[2].fields[3].value,
      email: Form[2].fields[4].value,

      city_name: Form[1].fields[4].value,
      state_name: Form[1].fields[5].value.state,
      locality: Form[1].fields[5].value.locality,
      gmap_meta_data: Form[1].fields[5].value.gmap_meta_data,
      zip: Form[1].fields[5].value.pincode,
      latitude: Form[1].fields[5].value.latitude,
      longitude: Form[1].fields[5].value.longitude,
    };

    axios
      .post("v1/register", obj)
      .then((res) => {
        return axios.post(`v1/generateOtp`, {
          phoneNo: obj.phone,
          login_method,
          user_type: "Student",
        });
        // if(res.data!=="User is already registered"){
        // }
        // else{
        //   navigate('/dashboard')
        // }
      })
      .then((data) => {
        data = data.data;
        if (!data.is_success) {
          setShowOtpError(() => data.error.msg);
          return;
        }
        let otp = data.data.otp;
        // console.log(otp);
        setOtp(otp);
        if (login_method === "whatsapp") {
          // if otp is null or empty then it is sms otp
          const whatsappLink = `https://wa.me/917386936695?text=Log%20me%20in%20to%20Homeshiksha%20%3A%20H-${otp}`;
          setTimeout(() => {
            window.open(whatsappLink, "_blank");
          });
          checkForJWT("whatsapp", otp);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const checkForJWT = (login_method, otp = "") => {
    let findTokenInterval = setInterval(() => {
      getJWT(findTokenInterval, login_method, otp);
    }, 3000);
    setTimeout(() => {
      // console.log("Stoped verifing otp");
      clearInterval(findTokenInterval);
      setVerificationProcess(false);
    }, 2 * 60 * 1000);
  };

  const getJWT = async (tokenInterval, login_method, otp) => {
    // console.log("finding token ...", tokenInterval);
    try {
      // console.log({ phoneNo: Form[2].fields[3].value, otp: otp, login_method });
      let data = await axios.post(`v1/generateJWT`, {
        phoneNo: Form[2].fields[3].value,
        otp: otp,
        login_method,
      });
      // console.log(data.data);
      if (!data.data.is_success) return;
      localStorage.setItem(
        "homeshiksha-jwt",
        JSON.stringify(data.data.data.token)
      );
      localStorage.setItem("paymentPopUp", JSON.stringify(true));
      clearInterval(tokenInterval);
      setVerificationProcess(false);
      navigate("/dashboard");
      window.location.reload();
    } catch (error) {
      // console.log(error);
    }
    // stopFindingJWT()
  };

  const fetchCourses = useCallback(
    (catId, courseLevel = "") => {
      axios
        .get("v1/student/all-courses?category_id=" + categorys[catId])
        .then((response) => {
          let dataFromAPI = response.data;
          setApiData(() => dataFromAPI);
          let _streamsList = dataFromAPI.map((itm) => itm.stream_name);
          let _addedFields = [];
          const coursesList = dataFromAPI[0].courses.sort(
            (a, b) => a.length - b.length
          );
          switch (catId) {
            case "school education":
              _addedFields.push({
                type: "dropDown",
                open: courseLevel === "",
                name: "For Which Class you need a Tutor ?",
                placeHolder: "For Which Class you need a Tutor ?",
                options: [
                  "Primary / Pre-Primary",
                  "LKG / UKG",
                  "1st Class",
                  "2nd Class",
                  "3rd Class",
                  "4th Class",
                  "5th Class",
                  "6th Class",
                  "7th Class",
                  "8th Class",
                  "9th Class",
                  "10th Class",
                  "11th Class",
                  "12th Class",
                ],
                value: courseLevel,
              });
              _addedFields.push({
                type: "dropDown",
                open: courseLevel !== "",
                name: "Choose the school board ?",
                placeHolder: "Choose the school board ?",
                options: _streamsList,
                value: "",
              });
              _addedFields.push({
                type: "dropDown-selection",
                open: false,
                name: "Course",
                placeHolder: "Select Your Course",
                options: coursesList,
                value: [],
              });
              break;
            case "Intermediate Courses":
              _addedFields.push({
                type: "dropDown",
                open: courseLevel === "",
                name: "For Which Class you need a Tutor ?",
                placeHolder: "For Which Class you need a Tutor ?",
                options: [
                  "Primary / Pre-Primary",
                  "LKG / UKG",
                  "1st Class",
                  "2nd Class",
                  "3rd Class",
                  "4th Class",
                  "5th Class",
                  "6th Class",
                  "7th Class",
                  "8th Class",
                  "9th Class",
                  "10th Class",
                  "11th Class",
                  "12th Class",
                ],
                value: courseLevel,
              });
              _addedFields.push({
                type: "dropDown",
                open: courseLevel !== "",
                name: "Choose the school board ?",
                placeHolder: "Choose the school board ?",
                options: _streamsList,
                value: "",
              });
              _addedFields.push({
                type: "dropDown-selection",
                open: false,
                name: "Course",
                placeHolder: "Select Your Course",
                options: coursesList,
                value: [],
              });
              break;
            case "Learn Language":
              _addedFields.push({
                type: "dropDown",
                open: true,
                name: "Stream",
                placeHolder: "Select Your Stream",
                options: _streamsList,
                value: "",
              });
              _addedFields.push({
                type: "dropDown-selection",
                open: false,
                name: "Course",
                placeHolder: "Select Your Course",
                options: coursesList,
                value: [],
              });
              break;
            case "Curricular":
              _addedFields.push({
                type: "dropDown",
                open: true,
                name: "Stream",
                placeHolder: "Select Your Stream",
                options: _streamsList,
                value: "",
              });
              _addedFields.push({
                type: "dropDown-selection",
                open: false,
                name: "Course",
                placeHolder: "Select Your Course",
                options: coursesList,
                value: [],
              });
              break;
            case "Competitive Exams":
              _addedFields.push({
                type: "dropDown",
                open: true,
                name: "Exam",
                placeHolder: "Select Your Exam",
                options: _streamsList,
                value: "",
              });

              _addedFields.push({
                type: "dropDown-selection",
                open: false,
                name: "Course",
                placeHolder: "Select Your Course",
                options: coursesList,
                value: [],
              });
              break;
            case "Special Kids (ADHD /ADD /AUTISM)":
              _addedFields.push({
                type: "dropDown",
                open: true,
                name: "Child Age group",
                placeHolder: "Child Age group",
                options: _streamsList,
                value: "",
              });

              _addedFields.push({
                type: "dropDown-selection",
                open: false,
                name: "Course",
                placeHolder: "Select Your Course",
                options: coursesList,
                value: [],
              });
              break;
            case "IT Courses":
              _addedFields.push({
                type: "dropDown",
                open: false,
                name: "Stream",
                placeHolder: "Select Your Stream",
                options: _streamsList,
                value: _streamsList[0],
              });
              _addedFields.push({
                type: "dropDown-selection",
                open: true,
                name: "Course",
                placeHolder: "Select Your Course",
                options: coursesList,
                value: [],
              });
              break;
            default:
              return;
          }
          setForm((pre) => {
            let _Form = [...pre];
            _Form[0].fields = [_Form[0].fields[0], ..._addedFields];
            return _Form;
          });
        })
        .catch((err) => {});
    },
    [setApiData, setForm]
  );

  const makeTitle = (string) => {
    try {
      let arr = string.split(" ");
      arr = arr.map((itm) => itm.charAt(0).toUpperCase() + itm.slice(1));
      return arr.join(" ");
    } catch (error) {
      return string;
    }
  };

  const handleWhatsAppLogin = (e) => {
    e.preventDefault();
    setVerificationProcess(true);
    registerAndGetOTP("whatsapp");
    logEvent(EVENTS.WHATSAPP_REGISTER.event_name)

  };

  const handleVerifyBySMS = () => {
    setSmsOtpVerification(() => true);
    setVerificationProcess(() => true);
    registerAndGetOTP("sms");
    setTimeout(() => {
      setShowResendOtp(() => true);
    }, 2 * 60 * 1000);
    logEvent(EVENTS.SMS_REGISTER.event_name)

  };

  const handleOtpChange = (e) => {
    let otp = e.target.value;
    setOtp(() => otp);
    if (otp.length === 6) {
      setVerificationProcess(() => true);
      axios
        .post(`v1/verifyOtp`, {
          phoneNo: Form[2].fields[3].value,
          otp: otp,
        })
        .then((data) => {
          // console.log(data.data);
          checkForJWT("sms", otp);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      setVerificationProcess(() => false);
    }
  };

  const handledropDownOptionClick = useCallback(
    (field, index, step, option) => {
      setForm((prevForm) => {
        const newForm = [...prevForm];
        newForm[step].viewed = true;
        newForm[step].fields[index].value = option;

        if (field.name === "Choose Study Area") {
          fetchCourses(option);
        }
        if (field.name === "For Which Class you need a Tutor ?") {
          if (
            [
              "Primary / Pre-Primary",
              "LKG / UKG",
              "1st Class",
              "2nd Class",
              "3rd Class",
              "4th Class",
              "5th Class",
              "6th Class",
              "7th Class",
              "8th Class",
              "9th Class",
              "10th Class",
            ].includes(option)
          )
            fetchCourses("school education", option);
          else fetchCourses("Intermediate Courses", option);
        }
        if (
          [
            "Choose the school board ?",
            "Choose the college board ?",
            "Child Age group",
            "Stream",
            "Exam",
          ].includes(field.name)
        ) {
          const _courseList = apiData.find(
            (dataField) => dataField.stream_name === option
          );
          newForm[step].fields[index + 1].open = true;
          newForm[step].fields[index + 1].options = _courseList.courses.sort(
            (a, b) => a.length - b.length
          );
        }

        try {
          if (
            newForm[step].fields[index + 1].type === "dropDown" &&
            newForm[step].fields[index + 1].value === ""
          ) {
            newForm[step].fields[index + 1].open = true;
          }
        } catch (error) {
          // console.log("Error occurred:", error);
        }

        return newForm;
      });
    },
    [apiData, fetchCourses, setForm]
  );

  const handledropDownSelectOptionClick = (
    field,
    index,
    step,
    option,
    status
  ) => {
    setForm((prevForm) => {
      const newForm = [...prevForm];
      newForm[step].viewed = true;
      const optionIndxInValueArray =
        newForm[step].fields[index].value.indexOf(option);
      try {
        if (optionIndxInValueArray === -1 && !status) {
          newForm[step].fields[index].value.push(option);
        } else if (optionIndxInValueArray !== -1 && status) {
          newForm[step].fields[index].value.splice(optionIndxInValueArray, 1);
        }
        if (
          newForm[step].fields[index + 1].type === "dropDown" &&
          newForm[step].fields[index + 1].value === ""
        ) {
          newForm[step].fields[index + 1].open = true;
        }
      } catch (error) {}

      return newForm;
    });
  };

  const Step = (step) => (
    <div className="form--container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          let newForm = [...Form];
          let emptyField = [];
          newForm[step].fields.map((inpurField) => {
            if (inpurField.value.length === 0) emptyField.push(inpurField.name);
            if (inpurField.name == "Mobile")
              logEvent(EVENTS.GIVEN_MOBILE_NO.event_name, inpurField.value);
            return inpurField;
          });
          if (emptyField.length === 0) {
            logEvent(EVENTS["HS_STEP_NO_" +(step+1)].event_name);
            setCurrentStep((pre) => step + 1);
            setForm((pre) => {
              newForm[step].filled = true;
              newForm[step + 1].viewed = true;
              try {
                if (
                  newForm[step + 1].fields[0].type === "dropDown" &&
                  newForm[step + 1].fields[0].value === ""
                ) {
                  newForm[step + 1].fields[0] = {
                    ...newForm[step + 1].fields[0],
                    open: true,
                  };
                }
              } catch (error) {
                // console.log("error");
              }

              return newForm;
            });
          } else {
            setForm((pre) => {
              newForm[step].fields = newForm[step].fields.map((inputField) => {
                if (inputField.value === "")
                  return { ...inputField, open: true };
                return inputField;
              });
              return newForm;
            });
          }
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        {Form[step].fields.map((field, index, stepArray) => {
          let options = field.options;
          if (field.name === "Stream") {
            options = options.reverse();
          }
          let formComponent = <div>input failed </div>;
          switch (field.type) {
            case "dropDown":
              formComponent = (
                <div
                  className="selection--wrapper"
                  onClick={(e) => {
                    setForm((pre) => {
                      let newForm = [...pre];
                      newForm[step].fields[index] = {
                        ...field,
                        open: !field.open,
                      };
                      return newForm;
                    });
                  }}
                  onChange={(e) => {
                    if (!e.target.value) return;
                    handledropDownOptionClick(
                      field,
                      index,
                      step,
                      e.target.value
                    );
                  }}
                >
                  {field.open ? (
                    options.map((option, optionIndex) => (
                      <div
                        className={`selection--option-wrapper ${
                          field.open ? "showDropdown" : "hideDropdown"
                        }`}
                      >
                        <input
                          type="radio"
                          id={`${field.name}-${optionIndex}`} // Ensure unique IDs for each radio button
                          value={option}
                          checked={option === field.value}
                          name={field.name}
                          required
                          style={{ cursor: "pointer" }}
                        />
                        <label
                          htmlFor={`${field.name}-${optionIndex}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handledropDownOptionClick(
                              field,
                              index,
                              step,
                              option
                            );
                          }}
                        >
                          {makeTitle(option)}
                        </label>
                      </div>
                    ))
                  ) : field.value !== "" ? (
                    <div className={`selection--option-wrapper`}>
                      <input type="radio" readOnly={true} checked />
                      {makeTitle(field.value)}
                    </div>
                  ) : (
                    field.placeHolder
                  )}
                </div>
              );
              break;
            case "dropDown-selection":
              let coursesList = field.options;
              if (stepArray[1].name === "For Which Class you need a Tutor ?") {
                if (
                  ["Primary / Pre-Primary", "LKG / UKG"].includes(
                    stepArray[1].value
                  )
                )
                  coursesList = ["All Subjects"];
                else if (
                  [
                    "1st Class",
                    "2nd Class",
                    "3rd Class",
                    "4th Class",
                    "5th Class",
                    "6th Class",
                    "7th Class",
                  ].includes(stepArray[1].value)
                ) {
                  coursesList = coursesList.filter(
                    (course) =>
                      ![
                        "All Subjects",
                        "All Subjects Except Language",
                        "Physics",
                        "Chemistry",
                        "Biology",
                        "History",
                        "Geography",
                        "Political Science",
                      ].includes(course)
                  );
                  coursesList = [
                    "All Subjects",
                    "All Subjects Except Language",
                    ...coursesList,
                  ];
                } else if (
                  ["8th Class", "9th Class", "10th Class"].includes(
                    stepArray[1].value
                  )
                )
                  coursesList = coursesList.filter(
                    (course) =>
                      ![
                        "All Subjects",
                        "All Subjects Except Language",
                      ].includes(course)
                  );
                else if (
                  ["11th Class ", "12th Class"].includes(stepArray[1].value)
                )
                  coursesList = coursesList.filter(
                    (course) => !["Science", "Accountancy"].includes(course)
                  );
              }
              formComponent = (
                <div className="selection--wrapper">
                  {field.open ? (
                    coursesList.map((option, optionIndex) => (
                      <div
                        className={`selection--option-wrapper ${
                          field.open ? "showDropdown" : "hideDropdown"
                        }`}
                      >
                        <input
                          type="checkbox"
                          id={`${field.name}-${optionIndex}`} // Ensure unique IDs for each radio button
                          value={option}
                          checked={field.value.includes(option)}
                          name={field.name}
                          // required
                          onChange={(e) => {
                            if (e.target.value)
                              handledropDownSelectOptionClick(
                                field,
                                index,
                                step,
                                e.target.value,
                                field.value.includes(option)
                              );
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        <label
                          htmlFor={`${field.name}-${optionIndex}`}
                          style={{ cursor: "pointer" }}
                        >
                          {makeTitle(option)}
                        </label>
                      </div>
                    ))
                  ) : !field.value ? (
                    <div className={`selection--option-wrapper`}>
                      <input type="radio" readOnly={true} />
                      {field.value.map((opt) => makeTitle(opt))}
                    </div>
                  ) : (
                    field.placeHolder
                  )}
                </div>
              );
              break;
            case "address":
              formComponent = (
                <div>
                  <Autocomplete
                    apiKey={apiKey}
                    style={{ width: "99%" }}
                    onPlaceSelected={(place) => {
                      let addressComponents = place.address_components;
                      let pinCode = addressComponents.find((component) =>
                        component.types.includes("postal_code")
                      );
                      let locality = addressComponents
                        .map((geo) => geo.long_name)
                        .join(", ");
                      let city = addressComponents.find(
                        (component) =>
                          component.types.includes("locality") &&
                          component.types.includes("political")
                      );
                      let state = addressComponents.find(
                        (component) =>
                          component.types.includes(
                            "administrative_area_level_1"
                          ) && component.types.includes("political")
                      );
                      let latitude = place.geometry.location.lat();
                      let longitude = place.geometry.location.lng();
                      let value = {
                        pincode: pinCode ? pinCode.short_name : " ",
                        city: city ? city.long_name : " ",
                        state: state ? state.long_name : " ",
                        locality: locality,
                        latitude: latitude,
                        longitude: longitude,
                        gmap_meta_data: JSON.stringify(addressComponents),
                      };
                      logEvent(EVENTS.GIVEN_PINCODE.event_name)

                      setForm((pre) => {
                        let newForm = [...pre];
                        newForm[step].viewed = true;
                        newForm[step].fields[index] = {
                          ...field,
                          value: value,
                        };
                        return newForm;
                      });
                    }}
                    options={{
                      types: [
                        // "hospital",
                        // "pharmacy",
                        // "bakery",
                        // "sublocality",
                        // ,
                        // "landmark",
                        // "locality",
                        // "postal_code",
                        // "postal_code_prefix",
                        // "postal_code_suffix",
                        // "postal_town",
                        // "street_address",
                        // "street_number",
                        // "sublocality",
                        // "sublocality_level_1",
                        // "sublocality_level_2",
                        // "sublocality_level_3",
                        // "sublocality_level_4",
                        // "sublocality_level_5",
                        // "subpremise",
                        // "town_square",
                      ],
                      componentRestrictions: { country: "IN" }, // Set country to "IN" for India
                    }}
                    placeholder={field.placeHolder}
                  />
                </div>
              );
              break;
            case "verificationBtn":
              if (!smsOtpVerification) {
                formComponent = (
                  <div>
                    <div
                      className="login-btns-wrapper register-btns-wrapper"
                      id="whatsapp-login-btn"
                    >
                      <button
                        type="button"
                        className="registerPrimaryButton"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          handleWhatsAppLogin(e);
                        }}
                      >
                        <img src="./whatsapp-icon.svg" alt="whats app icon" />{" "}
                        Verify with Whatsapp
                      </button>
                      <img
                        src="./book-loader.gif"
                        style={{ width: "auto" }}
                        alt=" Loading . . .  "
                        className={`${
                          verificationProcess ? "" : "in"
                        }active-img`}
                      />
                    </div>
                    <div className="login-btns-wrapper register-btns-wrapper">
                      <button
                        type="button"
                        className="registerPrimaryButton"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          handleVerifyBySMS();
                        }}
                      >
                        Verify with Mobile SMS
                      </button>
                      <img
                        src="./book-loader.gif"
                        style={{ width: "auto" }}
                        alt=" Loading . . .  "
                        className={`${
                          verificationProcess ? "in" : "in"
                        }active-img`}
                      />
                    </div>
                  </div>
                );
              } else {
                formComponent = (
                  <>
                    <div
                      className="input-loading-wrapper"
                      style={{ flexDirection: "column" }}
                    >
                      {showOtpError && (
                        <div className="errorAlert">{showOtpError} </div>
                      )}
                      <input
                        type="tel"
                        placeholder="Enter your OTP"
                        className="loginInputField"
                        value={otp}
                        onChange={(e) => {
                          handleOtpChange(e);
                        }}
                        style={{ textAlign: "center" }}
                      />
                      <div className="genetating-otp-loader">
                        {verificationProcess && (
                          <img src="./book-loader.gif" alt=" Loading . . .  " />
                        )}
                      </div>
                    </div>
                    <div className="login-btns-wrapper">
                      <div>
                        {showResendOtp && (
                          <button
                            type="button"
                            className="loginPrimaryButton"
                            onClick={() => {
                              handleVerifyBySMS();
                            }}
                          >
                            Resend OTP
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                );
              }
              break;
            case "checkbox":
              formComponent = (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    required
                    type={field.type}
                    id={`${field.name}`}
                    name={`${field.name}`}
                    placeholder={`${field.placeHolder}`}
                    value={field.value}
                    onChange={(e) => {
                      let value = !field.value;
                      setForm((pre) => {
                        let newForm = [...pre];
                        newForm[step].viewed = true;
                        newForm[step].fields[index] = {
                          ...field,
                          value: value,
                        };
                        return newForm;
                      });
                    }}
                  />
                  <span style={{ marginLeft: "8px", fontWeight: "600" }}>
                    I have read and accept{" "}
                    <span
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setShowTC(true);
                      }}
                      style={{
                        color: "navy",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      Student Terms and Conditions.
                    </span>
                  </span>
                  <dialog open={showTC} className="tc-dialog-box">
                    <br />
                    <br />
                    1. You understand that HomeShiksha is a home tutoring
                    marketplace and will facilitate the finding of a quality
                    home tutor for your child.Home Shiksha is 100% free to the
                    parents/students.
                    <br />
                    <br />
                    2. You can contact the tutors directly and invite them to
                    conduct a free demo class. A tutor will conduct only 1 or
                    maximum 2 free demo classes. After the demo class Student /
                    Parent has to be informed to the HomeShiksha/tutor as to be
                    continued or not continued.
                    <br />
                    <br />
                    3. After first demo if you wish to continue with our
                    tutor.For Bangalore, Dehradun, Raipur, Chennai and
                    Vijayawada : You have to pay 50% of the tuition fee as
                    advanced payment to HomeShiksha for confirmation. From 2nd
                    month you can pay at the month end.For Hyderabad: You have
                    to pay 100% advanced payment to HomeShiksha for
                    confirmation. From 2nd month you can pay at the month
                    end.Student / Parent do not pay any advances to the Selected
                    Tutor directly. HomeShiksha is not responsible if the
                    existed tutor is not turning back after taking the Advance.
                    <br />
                    <br />
                    4. In consideration of the home tuition provided by the
                    tutor to your child, you agree to pay a predefined amount to
                    the tutor.Also if parents want to discontinue the tuition at
                    any time(before completetion of month) then they need to pay
                    the charges for exact number of days the tutor came to
                    teach.In case if its the starting month then they need to
                    pay the charges for exact number of days the tutor came to
                    teach including the demo class.
                    <br />
                    <br />
                    5. You understand that, to the extent possible, HomeShiksha
                    will facilitate finding a tutor that best suit your child's
                    requirement but it does not guarantee any success of any
                    kind in any examination or test that your child may
                    undertake.
                    <br />
                    <br />
                    6. You understand that in the case where the tutor fails to
                    deliver first 15 scheduled tuitions, Homeshiksha will
                    facilitate finding a replacement tutor for your child within
                    a reasonable period of time.
                    <br />
                    <br />
                    7. You understand that home tutors need to be treated with
                    respect and dignity. Further, you agree to provide in your
                    home an environment conducive to one on one study during the
                    currency of the said home tuition.
                    <br />
                    <br />
                    8. You understand that the final decision to choose a
                    particular tutor for your child is yours and yours alone.
                    You explicitly agree to indemnify HomeShiksha from any harm
                    that may be caused by any advertent or inadvertent actions
                    of the tutor.
                    <br />
                    <br />
                    9. You understand that the tutor will teach your child if
                    and only if at least one parent is present in the house.
                    <br />
                    <br />
                    10. HomeShiksha will not give any guarantee of the Student's
                    progress and also not responsible if any stolen things
                    happen by the tutor.Tutors being observed by the parents /
                    Care Takers during the tuition time.
                    <br />
                    <br />
                    11. HomeShiksha reserves all the rights to change all or any
                    of these above mentioned terms and conditions with or
                    without giving any prior notice.
                    <br />
                    <br />
                    <button
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setShowTC(false);
                      }}
                    >
                      Got It
                    </button>
                  </dialog>
                </div>
              );
              break;
            default:
              formComponent = (
                <input
                  required
                  type={field.type}
                  id={`${field.name}`}
                  name={`${field.name}`}
                  placeholder={`${field.placeHolder}`}
                  value={field.value}
                  onChange={(e) => {
                    let value;
                    if (field.type == "tel")
                      value = e.target.value.replace(/\D/g, "").slice(-10);
                    else value = e.target.value;
                    setForm((pre) => {
                      let newForm = [...pre];
                      newForm[step].viewed = true;
                      newForm[step].fields[index] = {
                        ...field,
                        value: value,
                      };
                      return newForm;
                    });
                  }}
                />
              );
          }

          return (
            <div key={index}>
              <div className="input-group">
                <label htmlFor={`${field.name}`}>
                  {!field.open ? field.name : field.placeHolder}
                </label>
                {formComponent}
              </div>
            </div>
          );
        })}

        <div className="form-buttons-wrapper">
          {currentStep > 0 && currentStep < 3 && (
            <button
              className="secondary-btn"
              type="button"
              onClick={(e) => setCurrentStep(step - 1)}
            >
              Previous
            </button>
          )}
          {currentStep < 3 && (
            <button className="primary-btn" type="submit">
              Next
            </button>
          )}
          {/* {currentStep === 5 && <button className="primary-btn" type="button">Save</button>} */}
        </div>
      </form>
    </div>
  );

  function getRandomRGBColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b},.35)`;
  }

  useEffect(() => {
    let jwt = JSON.parse(localStorage.getItem("homeshiksha-jwt"));
    if (!jwt) {
      localStorage.setItem("userDetails", JSON.stringify({}));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/dashboard");
      window.location.reload();
    }
  }, []);

  return (
    <main className="main need-a-tutor">
      <section className="top-session">
        <div className="need-a-tutor-top-session--leftpart-wrapper">
          <div className="need-a-tutor-biglogo--wrapper">
            <img
              src="./main-pic-in-register-page.png"
              alt="main-pic-in-register-page pic"
            />

            <div className="need-a-tutor-biglogo--title">
              Elevating{" "}
              <span style={{ color: "rgba(43, 147, 165, 1)" }}>Education</span>,
              <br /> One{" "}
              <span style={{ color: "rgba(43, 147, 165, 1)" }}>Home</span> at a
              Time
            </div>
          </div>
        </div>
        <div className="need-a-tutor-top-session--rightpart-wrapper">
          <div className="need-a-tutor-top-session--rightpart--form-wrapper">
            <div className="form_title">find a tutor</div>
            <div className="form-status--wrapper">
              <div className="steps-wrapper">
                {Form.map((item, index) => (
                  <div className="step-status-wrapper" key={index}>
                    <div
                      onClick={(e) => {
                        if (Form[index].viewed) setCurrentStep((pre) => index);
                      }}
                    >
                      Step {index + 1}{" "}
                    </div>
                    <div
                      onClick={(e) => {
                        if (Form[index].viewed) setCurrentStep((pre) => index);
                      }}
                      className={`step-status-circle ${
                        Form[index].viewed ? "blue-border" : ""
                      } ${Form[index].filled ? "blue-bg" : ""}`}
                    ></div>
                  </div>
                ))}
              </div>
              <div className="line-wrapper-under-steps-wrapper"></div>
            </div>
            <div className="form--wrapper">{Step(currentStep)}</div>
          </div>
        </div>
      </section>

      <div className="reviews">
        <div>
          <div>
            <img src="./google-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              Google
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.8</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>1,013+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./jd-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              JustDail
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.7</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>827+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./mouth-shut-icon.gif" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.5</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>1177+ </span>
            reviews
          </div>
        </div>

        <div>
          <div>
            <img src="./glassdoor-icon.svg" alt="profilePic" />
            <span
              style={{ color: "rgba(130, 130, 130, 1)", fontWeight: "600" }}
            >
              {" "}
              Glassdoor
            </span>
          </div>
          <div>
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
            <img src="./star-icon.svg" alt="star icon" className="star-icon" />
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            Trusted Source{" "}
            <span style={{ color: "black", fontWeight: "500" }}>4.8</span>{" "}
          </div>
          <div
            style={{ color: "rgba(130, 130, 130, 1)" }}
            className="review-content"
          >
            <span style={{ color: "black", fontWeight: "500" }}>50+ </span>
            reviews
          </div>
        </div>
      </div>

      <section className="certified-by-india-section">
        <div className="certified-by-india-section--title">
          Certified by Indian Government
        </div>
        <div className="certified-by-india-section--midline"></div>
        <div className="certified-by-india-section--imgs-wrapper">
          <div>
            <img src="./msme-img.png" alt="msme img" />
          </div>
          <div>
            <img src="./startupindia-img.png" alt="start up india img" />
          </div>
        </div>
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">How it works?</div>
            <div>
              HomeShiksha is the leading provider of private tutoring.We are
              rapidly growing organisation that offers huge possibilities for
              your career advancement.
            </div>
          </div>
          <div className="small-section--text-right">
            HomeShiksha has a mission to make world class home-tuitions, online
            tuitions and e-tuitions accessible and dedicated to fulfill dreams
            of education across India.We help find learning options for students
            as well as adults in academic and non academic area.
          </div>
        </div>
      </section>

      <section className="how-it-works--wrapper">
        <div className="how-it-works-parts">
          <div className="how-it-works-step--wrapper">
            <div className="how-it-works-step--image-wrapper">
              <div
                className="how-it-works-step--image--container"
                style={{ backgroundColor: "rgba(255, 236, 221, 1)" }}
              >
                <img src="./step-1-icon.svg" alt="step-1-icon pic" />
                <img
                  className="how-it-works-step--curve--image down-curve"
                  src="./down-curve-arrow.svg"
                  alt="down-curve-arrow"
                />
              </div>
            </div>
            <div className="how-it-works-step--content--container">
              <div className="step-no">01</div>
              <div className="step-title">Step</div>
              <div className="step-desc">Give your Requirements</div>
            </div>
          </div>

          <div className="how-it-works-step--wrapper">
            <div className="how-it-works-step--content--container">
              <div className="step-no">02</div>
              <div className="step-title">Step</div>
              <div className="step-desc">
                DOORSTEP/ONLINE Our executive will provide the expert online
                tutor or home tutor at your doorstep for free.
              </div>
            </div>
            <div className="how-it-works-step--image-wrapper">
              <div
                className="how-it-works-step--image--container"
                style={{ backgroundColor: "rgba(148, 222, 244, 1)" }}
              >
                <img src="./step-2-icon.svg" alt="step-2-icon pic" />
                <img
                  className="how-it-works-step--curve--image up-curve"
                  src="./up-curve-arrow.svg"
                  alt="up-curve-arrow pic"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="how-it-works-parts">
          <div className="how-it-works-step--wrapper">
            <div className="how-it-works-step--image-wrapper">
              <div
                className="how-it-works-step--image--container"
                style={{ backgroundColor: "rgba(148, 222, 244, 1)" }}
              >
                <img src="./step-3-icon.svg" alt="step-3-icon pic" />
                <img
                  className="how-it-works-step--curve--image down-curve"
                  src="./down-curve-arrow.svg"
                  alt="down-curve-arrow"
                />
              </div>
            </div>
            <div className="how-it-works-step--content--container">
              <div className="step-no">03</div>
              <div className="step-title">Step</div>
              <div className="step-desc">
                FREE DEMO Take free demo class from our expert/verified tutors
              </div>
            </div>
          </div>

          <div className="how-it-works-step--wrapper">
            <div className="how-it-works-step--content--container">
              <div className="step-no">04</div>
              <div className="step-title">Step</div>
              <div className="step-desc">
                CONFIRM Choose the expert tutor and start the tuition and be
                free from your worries...
              </div>
            </div>
            <div className="how-it-works-step--image-wrapper">
              <div
                className="how-it-works-step--image--container"
                style={{ backgroundColor: "rgba(230, 191, 244, 1)" }}
              >
                <img src="./step-4-icon.svg" alt="step-4-icon pic" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">
              Our service is 100% free to parents and students.{" "}
            </div>
            {/* <div>is simply dummy text of the printing and typesetting industry.</div> */}
            <div>
              <div
                style={{
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "20px 8px",
                    padding: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(43, 147, 165, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Get Free Demo Class
              </div>
            </div>
            <div>
              <div
                style={{
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "20px 8px",
                    padding: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(43, 147, 165, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Get Female Tutors for Female Candidates.
              </div>
            </div>
            <div>
              <div
                style={{
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "20px 8px",
                    padding: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(43, 147, 165, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Get Home Tutor at your door step.
              </div>
            </div>
            <div>
              <div
                style={{
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "20px 8px",
                    padding: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(43, 147, 165, 1)",
                  }}
                >
                  <img
                    src="./points-tic-icon.svg"
                    width={15}
                    alt="points-tic-icon"
                  />
                </div>
                Get Tutors for any category academic and non academic
              </div>
            </div>
          </div>
          <div className="second-main-pic-wrapper">
            <img
              src="./second-main-pic.svg"
              className="second-main-pic"
              alt="second-main-pic"
            />
          </div>
        </div>
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">
              Frequent asked questions{" "}
            </div>
            {/* <div>is simply dummy text of the printing and typesetting industry.</div> */}
          </div>
          {/* <div className="small-section--text-right">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's is simply dummy text of the printing and typesetting industry</div> */}
        </div>
      </section>

      <section>
        <Faq data={data} styles={styles} config={config} />
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">
              Meet Our highly skilled Tutors
            </div>
            {/* <div>is simply dummy text of the printing and typesetting industry.</div> */}
          </div>
          <div className="small-section--text-right">
            Let's have a look to our expert and experienced home tutors and
            online tutors.
          </div>
        </div>
      </section>

      <section className="tutors-slider-cards">
        <ReactCardSlider slides={slides} />
      </section>

      <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">Customer Say</div>
            {/* <div>is simply dummy text of the printing and typesetting industry.</div> */}
          </div>

          {/* <div className="small-section--text-right">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's is simply dummy text of the printing and typesetting industry</div> */}
        </div>
      </section>

      <section className="customer-say--slider-cards">
        <div className="customer-say-slider--wrapper">
          {customerReviews.map((review) => (
            <div
              className="customer-say-slider-card"
              style={{ backgroundColor: getRandomRGBColor() }}
            >
              <div className="customer-say-slider-card--main-container">
                <div className="customer-say-slider-card-title">
                  {review.reviewTitle}
                </div>
                <div className="customer-say-slider-card-desc">
                  {review.content}
                </div>
              </div>

              <div className="customer-say-slider-card--small-container">
                <div className="customer-say-slider-card--img-wrapper">
                  <img src="./Mountains.svg" alt="mountains pic" />
                </div>
                <div className="customer-say-slider-card--customer--info-wrapper">
                  <div className="customer-say-slider-card--customer-name">
                    {review.name}
                  </div>
                  <div className="customer-say-slider-card-desc">
                    {review.location}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* <section className="small-section">
        <div className="small-blue-border"></div>
        <div className="small-section--text">
          <div className="small-section--text-left">
            <div className="small-section--text-left--title">
              Download our app!
            </div>
            <div>
              is simply dummy text of the printing and typesetting industry.
            </div>
          </div>
          <div className="small-section--text-right">
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's is simply dummy text of the printing
            and typesetting industry is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's is simply
            dummy text of the printing and typesetting industry
          </div>
        </div>
      </section> */}

      {/* <section className="footer-section"></section> */}
    </main>
  );
}
